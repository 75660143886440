<template>
  <div :class='getChapterClass' @click="clickChapter">
    <div :class='getTitleClass'>{{id+1}}.{{title}}</div>    
        <!-- <img :class="getWaterFlowClass" src="../assets/images/waterflow.gif" alt="">
        <div class="progressContainer"></div> -->
        <img class="chapter-img" :class='getImageStatus' :src="getBG" alt="">
        <img class="chapter-fill" :src="getBG" alt="" :style="getProgressStyle">
        <img class="finished-img" :class='getFinishedClass' src="../assets/images/checkmark.png" alt="">
  </div>
</template>
<script>

export default {
  name: 'AppMenuChapter',
   data() {
        return {
          isMuted: true,
          getLoop: true,
          getSource: "assets/video/drone background.mp4"
        };
    },
  props: {
    id: {
      type:Number,
      default:0
    },
    xPos: {
      type:Number,
      default:0
    },
    yPos: {
      type:Number,
      default:0
    },
    title: {
      type:String,
      default:"no title",
    },
    targetSection: {
      type:Number,
      default:0
    },
    progress: {
      default:0
    },
    sectionStatus: {

    }
  },
  components: {
  },
  computed: {
    getProgressStyle() {
      return "clip-path: polygon(0% 100%, 100% 100%, 100%      " + (100-this.progress) + "%, 0%      "+ (100 - this.progress) +"%);"
    },
    getTitleClass() {
      var result = ['chapter-title-'+this.id, "chapterUnlocked"];
        switch(this.sectionStatus) {
        case 0:
          result = ['chapter-title-'+this.id, "chapterLocked"]
          break;
        case 1:
          result = ['chapter-title-'+this.id, "chapterUnlocked"]
          break;
        case 2:
          result = ['chapter-title-'+this.id,""]
          break;
        default:
          result = "chapterLocked"
        } 
      return result;
    },
    getImageStatus() {
      var result = ["imgUnlocked"];
      if(this.sectionStatus <1) {      
        result = ["imgLocked"]
      }
      if(this.sectionStatus == 2) {
        result = "imgNoGlow"
      }
      return result;
    },
    getWaterFlowClass() {
      var result;
      if(this.progress < 100) {
        result = "waterflowHidden"
      } else {
        result = "waterflow-"+this.id;
      }
      return result;
    },
    getBG() {
      return "assets/images/menu stroke "+(this.id)+".png"
    },
    getChapterClass() {
      var result;
      result = "chapter-"+this.id;
      return result;
    }
    ,
    getFinishedClass() {
        var result = "";
        switch(this.sectionStatus) {
        case 0:
          result = ['finished-img-'+this.id, "hidden"]
          break;
        case 1:
          result = ['finished-img-'+this.id, "hidden"]
          break;
        case 2:
          result = ['finished-img-'+this.id,"chapterFinished"]
          break;
        default:
          result = ""
        } 
      return result;
    }
  },
  methods: {
    clickChapter() {
          this.emitter.emit("clearLastQuizScore");
          //  this.emitter.emit("GoTo",{section:this.targetSection, page:0})
     
      if(this.sectionStatus >=1) {
         this.emitter.emit("GoTo",{section:this.targetSection, page:0})
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.hidden {
  display:none;
}
.chapter-0,.chapter-1,.chapter-2,.chapter-3,.chapter-4,.chapter-5 {
  position:absolute;
  height:15%;
}

.progressContainer {
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
}

.chapter-title-0, .chapter-title-1, .chapter-title-2, .chapter-title-3, .chapter-title-4, .chapter-title-5 {
  position:absolute;
  padding:0;
  margin:0;
  color:#FFF;
  font-family: "Waterlily";
  text-transform: uppercase;
  cursor: pointer;
  z-index:2;
  /* font-size:0.8em; */
  font-size: calc(1em + 5 * ((100vw - 320px) / 680));
  z-index:999;
}
.waterflow-0 {
  position:absolute;
  top:50%;
  left:75%;
  width:20%;
  height:70%;
  z-index:1;
}
.chapter-0 {
  position:absolute;
  top:0%;
  width:62%;
  left:20%;
  z-index:2;
}
.chapter-title-0 {
  left:10%;
  top:25%;
  transform: rotate(5deg);
}
.waterflow-1 {
  position:absolute;
  top:50%;
  left:0;
  width:20%;
  height:auto;
  z-index:3;
}
.chapter-1 {
  position:absolute;
  top:15%;
  width:62%;
  left:30%;
  z-index:4;
}
.chapter-title-1 {
  left:10%;
  top:40%;
  transform: rotate(-5deg);
}

.waterflow-2 {
  position:absolute;
  top:0;
  left:0;
  width:20%;
  height:auto;
  z-index:5;
}
.chapter-2 {
  position:absolute;
  top:33%;
  width:62%;
  left:10%;
  z-index:6;
}
.chapter-title-2 {
  left:10%;
  top:25%;
  transform: rotate(-3deg);
}
.waterflow-2 {
  position:absolute;
  top:30%;
  left:80%;
  width:20%;
  height:auto;
  z-index:7;
}

.chapter-3 {
  position:absolute;
  top:45%;
  width:62%;
  left:30%;
  z-index:8;
}
.chapter-title-3 {
  left:10%;
  top:44%;
  transform: rotate(-6deg);
}
.waterflow-3 {
  position:absolute;
  top:55%;
  left:8%;
  width:20%;
  height:auto;
  z-index:9;
}


.waterflow-4 {
  position:absolute;
  top:45%;
  left:80%;
  width:20%;
  height:auto;
  z-index:9;
}
.chapter-4 {
  position:absolute;
  top:60%;
  width:62%;
  left:10%;
  z-index:10;
}

.chapter-title-4 {
  left:10%;
  top:32%;
  transform: rotate(10deg);
}

.waterflow-5 {
  position:absolute;
  top:60%;
  left:80%;
  width:20%;
  height:auto;
  z-index:9;
}
.chapter-5 {
  position:absolute;
  top:75%;
  width:62%;
  left:25%;
  z-index:10;
}

.chapter-title-5 {
  left:15%;
  top:42%;
  transform: rotate(-5deg);
}

.chapter-img {
  width:100%;
  height:100%;
  -webkit-filter: grayscale(100%) brightness(120%);
  filter: grayscale(100%) brightness(120%);
}
.chapter-fill {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.imgUnlocked {
  -webkit-filter: grayscale(100%) brightness(120%);
  filter: grayscale(100%) brightness(120%) drop-shadow(0 0 0.75rem rgb(0, 162, 255));
}
@media only screen and (max-width: 600px) {
  .imgUnlocked {
    -webkit-filter: grayscale(100%) brightness(70%);
    filter: grayscale(100%) brightness(70%) drop-shadow(0 0 0.25rem rgb(0, 162, 255));
  }
} 

@media only screen and (min-width: 768px) {
  .imgUnlocked {
    -webkit-filter: grayscale(100%) brightness(70%);
    filter: grayscale(100%) brightness(70%) drop-shadow(0 0 0.3rem rgb(0, 162, 255));
  }
  
} 

@media only screen and (min-width: 992px) {
  .imgUnlocked {
    -webkit-filter: grayscale(100%) brightness(70%);
    filter: grayscale(100%) brightness(70%) drop-shadow(0 0 0.5rem rgb(0, 162, 255));
  }
} 

@media only screen and (min-width: 1200px) {
  .imgUnlocked {
    -webkit-filter: grayscale(100%) brightness(70%);
    filter: grayscale(100%) brightness(70%) drop-shadow(0 0 0.5rem rgb(0, 162, 255));
  }
} 

.imgNoGlow {
  -webkit-filter: none;
  filter:none;
}

.footer {
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  height:8%;
}
h3 {
  display:inline;
  float:left;
  font-size:1em;
  margin:0;
  margin-left:5%;
  color:#FFF;
  text-shadow: 2px 2px 3px rgba(0,0,0,0.5);
}
h4 {
  display:inline;
  float:right;
 
  font-size:1em;
  margin:0;
   margin-right:5%;
   color:#FFF;
   text-shadow: 2px 2px 3px rgba(0,0,0,0.5);
}
.chapterLocked {
  color:rgb(116, 116, 116);
}

.chapterUnlocked {
  
}

.chapterUnlocked::after {
  color:#FFF;
  font-family: "FontAwesome";
  content: " \f101";
}
.chapterLocked::after {
 font-family: "FontAwesome";
 font-size:.9em;
 padding-right:0.5em;
 padding-left:0.2em;

 content: "    \f023";
}
.chapterFinished::after {
 font-family: "FontAwesome";
 font-size:1em;
 padding-right:0.5em;
 padding-left:0.2em;
 content: "    \f00c";
}

.waterflowHidden {
  display:none;
}

.finished-img-0 {
  position:absolute;
  height:30%;
  right:0%;
  top:45%;
  z-index:5;
}
.finished-img-1 {
  position:absolute;
  height:30%;
  top:15%;
  right:-2%;
  z-index:5;
  transform: rotate(-10deg);
}
.finished-img-2 {
  position:absolute;
  height:30%;
  top:25%;
  right:5%;
  z-index:5;
  transform: rotate(5deg);
}
.finished-img-3 {
  position:absolute;
  height:30%;
  top:20%;
  right:0%;
  z-index:5;
  transform: rotate(-10deg);
}
.finished-img-4 {
  position:absolute;
  height:30%;
  right:3%;
  top:50%;
  z-index:5;
}
.finished-img-5 {
  position:absolute;
  height:30%;
  right:0%;
  top:20%;
  z-index:5;
}
</style>