<template>
  <div class="instruction" @click='closeInstruction'>
      <div class="middle">
        <p>&nbsp;</p>
        <video autoplay playsinline loop preload="auto"> <source :src="this.instructionSrc"> </video>
        <button class='ok' >OK!</button>
       </div>
    </div>
</template>
<script>
export default {
  name: 'AppInstructions',
  props: {
    instructionSrc: {
    }
  },
  components: {
  },
  computed: {
  },
  mounted() {
  },
  methods:{
    closeInstruction() {
      this.emitter.emit("hideInstruction");
      this.emitter.emit("instructionFirstTimeShown");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.instruction {
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  z-index:99999999;
}

.ok {
  display:block;
  margin: 0 auto;
  margin-bottom: 10vh;
  font-size: calc(20px + 8 * ((100vw - 320px) / 680));
  font-family: "waterlily";
  padding:6px 44px 12px 44px;
  background:url("../assets/images/menu stroke 3.png") no-repeat center center;
  background-size:100% 100%;
}

p {
  padding:3vh 5vw 3vh 5vw;
  color:#55B6F8;
  font-family: "Soho";
  font-weight:900;
  font-size: calc(14px + 8 * ((100vw - 320px) / 680));
  margin:1vh 0 0 0;
  padding:0;
  margin-top:4vh;
  margin-bottom:-3vh;
}
p::after {
  /* font-size:3em;
  font-family: "FontAwesome";
  content: "\f059 ";    */
}
.middle {
  background:url("../assets/images/stroke white instructions.png") no-repeat center center;
  background-size: auto 100%;
  position: absolute;
  top: 50%;
  width:100%;
  transform: translateY(-50%);
  padding-bottom:5vh;
}

video  {
  height:50%;
  width:auto;
  max-width:75%;
  max-height:20vh;
  margin-bottom:10px;

}


@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .instruction {
    background:rgba(0,0,0,.5);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .instruction {
    background:rgba(0,0,0,.75);
  }
}
</style>
