<template>
  <div class="appquizscore">
    <transition appear name='slide-down'>
    <div class="top">
      <h2>JOUW HIGH SCORE:</h2>
    </div>
    </transition>
      <transition appear name='slide-down'>
        <div class="middle">
          <img class='star' src='../assets/images/star-yellow.png'><h1>x {{currentQuizScore + this.data.defaultStars}}</h1>
        </div>
      </transition>
      <transition appear name='slide-up'>
      <div>
        <h3>Je bent nu klaar met</h3>
        <h4>{{data.title}}</h4>
      </div>
      </transition>
      <transition appear name='slide-up'>
        <div class="bottom">
          <button @click="finish" class="btn-start">Verder</button>
        </div>
      </transition>
  </div>
</template>

<script>
export default {
  name: 'AppQuizScore',
  props: {
      data: {
        type:Object,
        required: true
      },
      currentSection: {
        type:Number,
        required: true
      },
      score: {
        type:Number,
        default:0,
      },
      currentQuizScore: {

      }
  },
  component: {
  },
  computed: {
  },
  methods: {
    finish() {
      this.emitter.emit("completeCurrentSection")
      this.emitter.emit("setCurrentPageScore", this.score)
      this.emitter.emit("completeCurrentPage");
      this.emitter.emit("goToMenu");
    },
    mounted() {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.title {
  display:block;
  position:absolute;
  top:20%;
  left:0;
  width:100%;
  text-align: center;  
}

h2 {
  position:absolute;
  bottom:10%;
  left:0;
  width:100%;
  height:auto;
  padding:0;
  margin:0;
  font-size:1.5em;
  color:#FFF;
  font-family:"Soho";
  font-weight:500;
}

.top {
  position:relative;
  width:100%;
  height:20%;
  padding:0;
  margin:0;
}
.middle {
  display:flex;
  position:relative;
  justify-content: center;
  width:100%;
  height:34%;
  padding:0;
  margin:0;
  background:url("../assets/images/stroke white.png") no-repeat center center;
  background-size: auto 100%;
}

.bottom {
  display:flex;
  position:relative;
  justify-content: center;
  width:100%;
  height:46%;
  padding:0;
  margin:0;
}
.star {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height:75%;
  margin-left:-10%;
}
h1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size:3em;
  color:#FFDB28;
  font-family: "Waterlily";
  text-transform:uppercase;
  font-weight:900;
  margin-top:0;
}
h3 {
  font-size:1.5em;
  color:#FFF;
  font-family: "Soho";
  text-transform:uppercase;
  font-weight:500;
  margin:0;
}
h4 {
  font-size:2.4em;
  color:#FFF;
  font-family: "Waterlily";
  text-transform:uppercase;
  font-weight:500;
  margin:0;
}
.btn-start {
  font-family: "Soho";
  position:absolute;
  bottom:50%;
  font-size:2em;
  width:75%;
  height:40%;
  max-width:500px;
  background: url("../assets/images/stroke blue 1.png") no-repeat center center;
  background-size:contain;
}
.appquizscore {
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  text-align:center;
  z-index:30000;
}
</style>
