<template>
  <div class="appquizscore">
    <canvas id="myCanvas" width="1191" height="1684">
    </canvas>
    <img id='certificateSource' src="../assets/images/certificate_background.png" alt="">
    <img id='certificateKenner' src="../assets/images/certificate_kenner.png" alt="">
    <img id='certificateExpert' src="../assets/images/certificate_expert.png" alt="">
    <img id='certificateProfessor' src="../assets/images/certificate_professor.png" alt="">
    <img id='certificateLeerling' src="../assets/images/certificate_leerling.png" alt="">
    <img id='certificateBeginner' src="../assets/images/certificate_beginner.png" alt="">

    <audio id="audio_outro"  src="assets/audio/outro.mp3" @ended='audioEnded' preload="auto"></audio>
    <transition appear name='fade'>
    <div v-if='showLinkCopied' :key='link_1_1' class="linkCopied">LINK GEKOPIEERD</div>    
    </transition>
      <transition appear name='slide-down'>
        <div class="middle">
          <div class="starContainer"> <img class='star' :src='getNiveauRibbon'>
            <input class='nameInput' v-model="userName" :placeholder="placeholderText"  />
            <div class="score">
              <h1>{{totalScore}}</h1><h3> sterren</h3>
          </div>
          </div>
        </div>
      </transition>
      <transition appear name='fade'>
        <div class="bottom">
          <h6 @click='copyLink'>www.vangdewatermonsters.nl/e-learning</h6><br>
          <button @click="downloadCertificate" class="btn-certificate">DOWNLOAD CERTIFICAAT</button>
          <button @click="finish" class="btn-certificate">{{"< "}}VERBETER JE SCORE</button>
        </div>
      </transition>
  </div>
</template>h1 

<script>
export default {
  name: 'AppFinalScore',
  data() {
        return {
          userName: "",
          placeholderText: "VUL HIER JE NAAM IN",
          certificateImage: Image,
          showLinkCopied: false
        };
    },
  props: {
      data: {
        type:Object,
        required: true
      },
      currentSection: {
        type:Number,
        required: true
      },
      totalScore: {
        type:Number,
        default:0
      },
      studentName: {
        type:String,
        default:""
      }
  },
  component: {
  },
  computed: {
    getNiveauRibbon() {
      var result = "assets/images/certificate_beginner.png"
      if(this.totalScore >= 40 && this.totalScore < 65) {
        result ="assets/images/certificate_leerling.png"
      }
      if(this.totalScore >= 65 && this.totalScore < 75) {
        result ="assets/images/certificate_kenner.png"
      }
      if(this.totalScore >= 75 && this.totalScore < 81) {
        result ="assets/images/certificate_expert.png"
      }
      if(this.totalScore >= 81) {
        result ="assets/images/certificate_professor.png"
      }
      return result;
    }
  },
  watch: {
    userName() {
      this.emitter.emit("setName", this.userName)
    }
  },
  methods: {
    copyLink() {
      this.showLinkCopied = true;
      setTimeout(this.hideLinkCopied, 1500);
      navigator.clipboard.writeText("http://www.vangdewatermonsters.nl/e-learning/");
    },
    hideLinkCopied() {
      this.showLinkCopied = false;
    },
    finish() {
      this.emitter.emit("completeCurrentSection")
      this.emitter.emit("setCurrentPageScore", this.score)
      this.emitter.emit("completeCurrentPage");
      this.emitter.emit("goToMenu");
    },
    downloadCertificate() {
      var canvas = document.getElementById('myCanvas');
      var ctx = canvas.getContext('2d');

  // Draw slice
    
    ctx.drawImage(document.getElementById('certificateSource'), 0, 0, 1191, 1684, 0, 0, 1191, 1684);

    if(this.totalScore < 40) {
      ctx.drawImage(document.getElementById('certificateBeginner'), 0, 0, 480, 320, 220, 240, 720, 480);
    }

    if(this.totalScore >= 40 && this.totalScore < 65) {
      ctx.drawImage(document.getElementById('certificateLeerling'), 0, 0, 480, 320, 220, 240, 720, 480);
    }

    if(this.totalScore >= 65 && this.totalScore < 75) {
      ctx.drawImage(document.getElementById('certificateKenner'), 0, 0, 480, 320, 220, 240, 720, 480);
    }

    if(this.totalScore >= 75 && this.totalScore < 81) {
      ctx.drawImage(document.getElementById('certificateExpert'), 0, 0, 480, 320, 220, 240, 720, 480);
    }

    if(this.totalScore >= 81 ) {
      ctx.drawImage(document.getElementById('certificateProfessor'), 0, 0, 480, 320, 220, 240, 720, 480);
    }

    ctx.fillStyle = "#55b6f8";
    ctx.font = 'bold 64px Soho';
    var textWidth = ctx.measureText(this.userName).width;
      ctx.fillText(this.userName , (canvas.width/2) - (textWidth / 2), 790);

      ctx.font = 'bold 64px Soho';
      ctx.fillStyle = "#ffda25";
      var scoreText = String(this.totalScore+" sterren");
      textWidth = ctx.measureText(scoreText).width;
      ctx.fillText(scoreText , (canvas.width/2) - (textWidth / 2), 960);

      const link = document.createElement('a');
      link.download = 'Certificaat Waterkwaltieit.png';
      link.href = canvas.toDataURL();
      link.click();
      link.delete;
    }
  },
  mounted() {
    this.userName = this.studentName;
    this.emitter.emit("completeCurrentPage");
      var audio = document.getElementById("audio_outro");
        audio.play();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#certificateBeginner, #certificateKenner, #certificateExpert, #certificateProfessor, #certificateSource, #certificateLeerling, #myCanvas {
  display:none;
}

.linkCopied {
  position:absolute;
  top:70%;
  left:50%;
  max-width:200px;
  padding:30px;
  transform:translateX(-50%) translateY(-50%);
  font-weight:900;
  background:#FFF;
  color:rgb(102, 102, 102);
  z-index:100;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5)  ; 
	-webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5)  ; 
	-moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5)  ;
}
h5 {}

h6 {
  color:#FFF;
  font-size: calc(14px + 5 * ((100vw - 320px) / 680));
  margin:0;
  padding:0;
  cursor: pointer;
}
.title {
  display:block;
  position:absolute;
  top:20%;
  left:0;
  width:100%;
  text-align: center;  
}

h2 {
  position:absolute;
  bottom:10%;
  left:0;
  width:100%;
  height:auto;
  padding:0;
  margin:0;
  font-size: calc(30px + 10 * ((100vw - 320px) / 680));
  color:#FFF;
  font-family:"Soho";
  font-weight:500;
}

.top {
  position:relative;
  width:100%;
  height:20%;
  padding:0;
  margin:0;
}

.middle {
  position:absolute;
  top:25%;
  left:0;
  height:40%;
  width:100%;
  background:url("../assets/images/stroke white.png") no-repeat center center;
  background-size: auto 100%;
}

.starContainer {
    /*let's say your ratio is x:y
     let x be 1293
    let y be 1920*/
    width: 100vw;
    /* height= 100/(x/y) */
    height:148.4912vw; 
    max-height: 100vh;
    /*Max-width =  100*(x/y) */
    max-width:67.344vh;
    top:25%;
    left:50%;
    transform: translateX(-50%);
    /*centralise horizontally and vertically*/
    position: absolute;
}
.content {
  position:absolute;
  top:0%;
  left:0;
  width:100%;
  height:25%;
  z-index:99;
}

.star {
  position:absolute;
  top:-32%;
  left:50%;
  width:75%;
  height:auto;
  transform: translateX(-50%);
}

.score {
  position:absolute;
  top:6%;
  width:100%;
  max-height:200px;
  left:50%;
  transform: translateX(-50%);
}

.nameInput {
  position:absolute;
  text-align:center;
  width:75%;
  left:50%;
  font-size: calc(16px + 8 * ((100vw - 320px) / 680));
  transform: translateX(-50%);
  font-family: "Soho";
  font-weight:900;
  background:none;
  color:#55b6f8;
  border:none;
  margin-top:3%;
  padding:0;
}
.nameInput:focus {
  border:none;
  outline:none;
}

h1 {
  display:inline;
  font-size: calc(50px + 20 * ((100vw - 320px) / 680));
  color:#FFDB28;
  font-family: "Waterlily";
  text-transform:uppercase;
  font-weight:900;
  padding:0;
  margin:0;
}
h3 {
  display:inline;
  font-size: calc(20px + 10 * ((100vw - 320px) / 680));
  color:#FFDB28;
  font-family: "Soho";
  text-transform:uppercase;
  font-weight:900;
}
h4 {
  font-size:2.4em;
  color:#FFF;
  font-family: "Waterlily";
  text-transform:uppercase;
  font-weight:500;
  margin:0;
}
.bottom {
  position:absolute;
  width:50%;
  height:30%;
  max-width:800px;

  bottom:8%;
  left:50%;
  transform:translateX(-50%);
  width:100%;
  padding:0;
  margin:0;
}
.btn-menu {
  display:inline-block;
  font-family: "Soho";
  font-size:2em;
  background: url("../assets/images/stroke blue 1.png") no-repeat center center;
  background-size:100% 100%;
  padding:1em 3em 1em 3em;
  font-size: calc(16px + 8 * ((100vw - 320px) / 680));
}
.btn-certificate {
 display:inline-block;
  font-family: "Soho";
  font-size:2em;
  background: url("../assets/images/stroke blue 1.png") no-repeat center center;
  background-size:100% 100%;
  padding:1em 3em 1em 3em;
  font-size: calc(16px + 4 * ((100vw - 320px) / 680));
  margin-bottom:3%;
}
.appquizscore {
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  text-align:center;
  z-index:30000;
}
</style>
