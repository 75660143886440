<template>
  <div class="appstart">
    <div class="top">
      <img class="logo" src="../assets/images/nmlogo.png">
    </div>
      <div class="middle">
        <h1>Het water <br/>onder de loep!</h1>
      </div>
      <div class="bottom">
        <button @click="startApp" class="btn-start">Start</button>
        <h2>© Natuur & milieu 2022</h2>

      </div>
  </div>
</template>

<script>
export default {
  name: 'AppStart',
  props: {
      data: {
          type: Object,
          required: true
      },
      currentSection: {
        type:Number,
        required: true
      }
  },
  component: {
  },
  methods: {
    startApp() {
      this.emitter.emit("start-app");
    },
    mounted() {   
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.title {
  display:block;
  position:absolute;
  top:20%;
  left:0;
  width:100%;
  text-align: center;  
}

h2 {
  position:absolute;
  bottom:1.5vh;
  left:1.5vh;
  margin:0;
  color:#FFF;
  font-size:1em;
}

h3 {
  position:absolute;
  font-size:1em;
  bottom:1.5vh;
  right:1.5vh;
  margin:0;
  color:#FFF;
}

.top {
  position:relative;
  width:100%;
  height:20%;
  padding:0;
  margin:0;
}
.middle {
  display:flex;
  position:relative;
  justify-content: center;
  width:100%;
  height:34%;
  padding:0;
  margin:0;
  background:url("../assets/images/stroke white.png") no-repeat center center;
  background-size: auto 100%;
}

.bottom {
  display:flex;
  position:relative;
  justify-content: center;
  width:100%;
  height:46%;
  padding:0;
  margin:0;
}

h1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: calc(32px + 14 * ((100vw - 320px) / 680));
  color:#55B6F8;
  font-family: "Waterlily";
  text-transform:uppercase;
  font-weight:900;
  margin-top:0;
}
.btn-start {
  font-family: "Soho";
  position:absolute;
  bottom:40%;
  font-size:2em;
  width:75%;
  height:40%;
  max-width:500px;
  background: url("../assets/images/stroke blue 1.png") no-repeat center center;
  background-size:contain;
}
.appstart {
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  text-align:center;
}
.logo {
  position:absolute;
  width:25%;
  min-width:100px;
  max-width:150px;
  bottom:5%;
  left:50%;
  transform: translateX(-50%);  
}
</style>
