<template>
<div id="app">
  <app-rotate-device />
  <transition >
    <video-background  class="vidBG" src="assets/video/drone background.mp4"> </video-background>
  </transition>
  <div class="component">
      <transition appear :name="getTransitionType">
          <component ref="foo" v-cloak :is="currentComponent" :key="componentKey" :data="componentData" :chapterProgressData="chapterProgressData" :currentSection='currentSection' :currentPage='currentPage' :getCurrentSectionProgress='getCurrentSectionProgress' :totalScore='totalScore' :currentScore='currentScore' :currentQuizScore='currentQuizScore' :instructionFirstTime='instructionFirstTime' :studentName='studentName'></component>
      </transition>
  </div>

  
  </div>
</template>

<script>
import AppData from "./assets/json/data.json";

import AppStart from './components/AppStart.vue'
import AppChapterStart from './components/AppChapterStart.vue'
import AppNavMap from './components/AppNavMap.vue'
import AppHeader from './components/AppHeader.vue'
import AppRotateDevice from './components/AppRotateDevice.vue'
import AppTest from './components/AppTest.vue'
import AppVideo from './components/AppVideo.vue'
import AppIntroVideo from './components/AppIntroVideo.vue'
import AppDragSingleTarget from './components/AppDragSingleTarget.vue'
import AppMenu from './components/AppMenu.vue'
import AppNavMagnifier from './components/AppNavMagnifier.vue'
import AppTrueFalse from './components/AppTrueFalse.vue'
import AppMultipleChoice from './components/AppMultipleChoice.vue'
import AppCredits from './components/AppCredits.vue'
import AppFinalScore from './components/AppFinalScore.vue'


import AppQuizScore from './components/AppQuizScore.vue'
import AppDragDoubleTarget from './components/AppDragDoubleTarget.vue'

import VideoBackground from 'vue-responsive-video-background-player'

export default {
  name: 'App',
  components: {
    AppStart,
    AppNavMap,
    AppHeader,
    AppRotateDevice,
    AppTest,
    AppChapterStart,
    AppVideo,
    AppDragSingleTarget,
    AppIntroVideo,
    AppMenu,
    AppNavMagnifier,
    VideoBackground,
    AppQuizScore,
    AppDragDoubleTarget,
    AppTrueFalse,
    AppMultipleChoice,
    AppCredits,
    AppFinalScore
  },
   data() {
        return {
          currentSection: 0,
          currentPage: 0,
          testing: true,
          showBubbleTransition: false,
          appData: AppData,
          sectionStatuses: [],
          showHeader: true,
          chapterProgressData: [],
          showMenuBackground: false,
          totalScore: 0,
          currentQuizScore: 0,
          instructionFirstTime: true,
          studentName: ""
        };
    },
  methods: {
    saveLocalStorage() {
			var saveString = JSON.stringify(this.chapterProgressData);
      localStorage.setItem("suspendData-nm-YfkIsdsh12asdkHfdsjfsdhshj", saveString);
      
		},
    getLocalStorage() {
      var lsData = localStorage.getItem("suspendData-nm-YfkIsdsh12asdkHfdsjfsdhshj");
      var result;
      if (lsData == null) {
       this.setUpChapterProgress()
      } else {
        this.currentSection = 1;
        this.currentPage = 0; 
       this.showHeader = true;
        result = lsData
        result = JSON.parse(lsData);
        this.chapterProgressData = result;
      }
      var lsName = localStorage.getItem("suspendData-nm-skdxc5463ne47fdsgk");
      if(lsName != null) {
        this.studentName = lsName;
      }
    },
    updateTotalScore() {
      var result = 0;
      for(var i=0;i<this.chapterProgressData.length;i++) {
        var curSection = this.chapterProgressData[i].pages;
        for(var j=0;j<curSection.length;j++) {
          var curScore = curSection[j].score;
          result += curScore;
        }
      }
      this.totalScore =  result;
    },
    setUpChapterProgress() {
      // for(var i=0;i<this.appData.sections.length;i++) {
      //   var curSection = this.appData.sections[i].pages
      //   var arr = [];
      //   for(var j=0;j<curSection.length;j++) {
      //     var curPage = {status:0, score:0}
      //     arr.push(curPage)
      //   }
      //   this.chapterProgressData.push(arr)
      // }
      for(var i=0;i<this.appData.sections.length;i++) {
        var curSection = this.appData.sections[i].pages
        var obj = {};

        obj.status = 0;
        if(i == 0) {
          obj.status = 1;
        }
        obj.pages = [];
        for(var j=0;j<curSection.length;j++) {
          var curPage = {status:0, score:0}
          obj.pages.push(curPage)
        }
        this.chapterProgressData.push(obj)
      }
    },
    destroyBubbles() {
            this.showBubbleTransition = false;
    },
    startBubbleTransition() {
      // this.showBubbleTransition = true;
      // setTimeout(this.destroyBubbles, 2000);
    },
    nextPage() {
      this.currentPage++;
      this.startBubbleTransition();
    }
  },
 watch: {
    chapterProgressData: {
      handler(newValue) {
        this.saveLocalStorage();
        this.updateTotalScore();
      },
      deep: true,
    }
  },
  computed: {
    currentScore() {
      var result = 0;
      if(this.chapterProgressData.length !=0) {        
        result = this.chapterProgressData[this.currentSection].pages[this.currentPage].score;
      }
      return result;
    },
    getCurrentSectionProgress() {
        var result =0;
        if(this.chapterProgressData.length !=0) {
          var curSection = this.chapterProgressData[this.currentSection]
          var totalPages = curSection.pages.length;
          var completedPages = 0;
          for(var i=0;i<totalPages;i++) {
              var curPage = curSection.pages[i];
              if(curPage.status == 1) {
                  completedPages++;
              }
          }
        }
        result = (completedPages/totalPages) * 100;
        return result
    },
    getCurrentTitle() {
       return this.appData.sections[this.currentSection].pages[this.currentPage].title;
    },
    componentKey() {
      var result = "key-" + this.currentSection + "_" + [this.currentPage];
        return result;
    },
    componentData() {
      var result = this.appData.sections[this.currentSection].pages[this.currentPage];
      return result;
    },
    currentComponent() {
      var result = "app-" + this.componentData.type;
      return result;
    },
    getShowMenuBackground() {
      var result = false;
      if(this.componentData.type == "menu" || this.componentData.type=='start' || this.componentData.type=='quizScore') {
        result = true;
      } else {
        result = false;
      }
      return result;
    },
    getTransitionType() {
      var result = "slide-up-up"
      return result;
    }
  },
  mounted() { 
    this.getLocalStorage();
    this.emitter.on("setName", name => {
      this.studentName = name;
      localStorage.setItem("suspendData-nm-skdxc5463ne47fdsgk", this.studentName);
    });
    this.emitter.on("clearLastQuizScore", () => {
      this.currentQuizScore = 0;
    });
    this.emitter.on("instructionFirstTimeShown", () => {
      this.instructionFirstTime = false
      //
    });

    this.emitter.on("setCurrentQuizScore", score => {
      this.currentQuizScore = score;
    });

    this.emitter.on("setCurrentPageScore", score => {
      var prevScore =  this.chapterProgressData[this.currentSection].pages[this.currentPage].score;
      if(score > prevScore) {
        this.chapterProgressData[this.currentSection].pages[this.currentPage].score = score;
      }
    });

    this.emitter.on("GoTo", destination => {
      if(destination.section == 7 && destination.page == 0 && this.chapterProgressData[destination.section].pages[destination.page].status == 1) {
        this.currentPage = 1;
        this.currentSection = 7
      } else {
        this.currentPage = destination.page;
        this.currentSection = destination.section;
      }
      
    });

    this.emitter.on("setLastScore", score => {
      this.lastScore = score;
    });
    this.emitter.on("goToPage", destination => {
      this.currentPage = destination
    });
    this.emitter.on("goToNextPage", () => {
      this.currentPage++;
    });
    this.emitter.on("completeCurrentPage", () => {
      this.chapterProgressData[this.currentSection].pages[this.currentPage].status = 1;
    });
    this.emitter.on("completeCurrentSection", () => {
      var refSection = this.currentSection - 2;
      this.chapterProgressData[refSection].status = 2;
      if(this.chapterProgressData[refSection+1] != undefined) {
        this.chapterProgressData[refSection+1].status = 1;
      }
    });
     this.emitter.on("nextPage", () => {
        this.nextPage();
    });
    this.emitter.on("hideHeader", () => {
      this.showHeader = false;
    });
    this.emitter.on("showHeader", () => {
      this.showHeader = true;
    });

    this.emitter.on("nav-back", () => {
      this.currentPage--
    });

    this.emitter.on("goToMap", () => {
      this.currentPage = 0;
     });

    this.emitter.on("goToMenu", () => {
      this.currentSection = 1;
      this.currentPage = 0; 
       this.showHeader = true;
     });
    this.emitter.on("goToIntro", () => {
      this.currentSection = 0;
      this.currentPage = 0; 
       this.showHeader = true;
     });
     
     this.emitter.on("goToCredits", () => {
      this.currentSection = 8;
      this.currentPage = 0; 
       this.showHeader = true;
     });
     this.emitter.on("toggle", () => {
       this.testing = !this.testing
     });

     this.emitter.on("start-app", () => {
       this.currentSection = 0;
       this.currentPage = 1;
     });

     this.emitter.on("start-chapter", () => {
       this.showHeader = true;
       this.nextPage();      
     });
  },
  created() {
  }
}
</script>

<style>

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
html, body {
  height:100%;
  width:100%;
  margin:0;
  padding:0;
  background:#000;
  overscroll-behavior-y: contain;
    overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.component {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#app {
  position:fixed;
  font-family: "N&M Utrecht";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height:100%;
  width:100%;
  background:#000;
}
.vidBG {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:0;
}
@media screen and (orientation: landscape) and (max-width: 812px) {
    .vidBG {
      display:none;
    }
}
.vignette {
  position: fixed;
  pointer-events: none;
  z-index:998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 200px rgba(0,0,0,0.9) inset;
}

button, button:hover, button:active, button:focus {
  cursor: pointer;
  border:none;
  color:#FFF;
  background:none;
  font-family: "N&M Utrecht";
}
.btn-start {
  font-family: 'N&M Utrecht';
  font-size: calc(3em + (16 - 3) * ((100vw - 300px) / (1600 - 300)));
  padding:0;
  font-weight: bold;
  position: absolute;
  bottom:15%;
  left: 50%;
  transform: translate(-50%, 0);
  text-align:center;
}

/* TRANSITIONS */

/* Fade */
.fade-enter-active,
.fade-leave-active {
  
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

  /* slide in down */
  .slide-down-enter-active {
		animation: slide-down-in 1s ease-in-out forwards;
	}

	.slide-down-leave-active {
		animation: slide-down-out .6s ease-in-out forwards;
	}
   @keyframes slide-down-in {
     0% {
			-webkit-transform: translateY(-101%);
			-moz-transform: translateY(-101%);
			-ms-transform: translateY(-101%);
			-o-transform: translateY(-101%);
			transform: translateY(-101%);
		}
		100% {
			-webkit-transform: translateY(0);
			-moz-transform: translateY(0);
			-ms-transform: translateY(0);
			-o-transform: translateY(0);
			transform: translateY(0);
		}
	}

	@keyframes slide-down-out {
		from {
			-webkit-transform: translateY(0);
			-moz-transform: translateY(0);
			-ms-transform: translateY(0);
			-o-transform: translateY(0);
			transform: translateY(0);
		}

		to {
			-webkit-transform: translateY(-101%);
			-moz-transform: translateY(-101%);
			-ms-transform: translateY(-101%);
			-o-transform: translateY(-101%);
			transform: translateY(-101%);
		}
	}

   /* slide in from right and slide out to left */
  .slide-left-enter-active {
		animation: slide-left-in .6s ease-in-out forwards;
	}

	.slide-left-leave-active {
		animation: slide-left-out .6s ease-in-out forwards;
	}
   @keyframes slide-left-in {
     0% {
			-webkit-transform: translateX(101%);
			-moz-transform: translateX(101%);
			-ms-transform: translateX(101%);
			-o-transform: translateX(101%);
			transform: translateX(101%);
		}
		100% {
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-ms-transform: translateX(0);
			-o-transform: translateX(0);
			transform: translateX(0);
		}
	}

	@keyframes slide-left-out {
		from {
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-ms-transform: translateX(0);
			-o-transform: translateX(0);
			transform: translateX(0);
		}

		to {
			-webkit-transform: translateX(-101%);
			-moz-transform: translateX(-101%);
			-ms-transform: translateX(-101%);
			-o-transform: translateX(-101%);
			transform: translateX(-101%);
		}
	}


   /* slide in from left */
  .slide-right-enter-active {
		animation: slide-right-in 1s ease-in-out forwards;
	}

	.slide-right-leave-active {
		animation: slide-right-out .6s ease-in-out forwards;
	}
   @keyframes slide-right-in {
     0% {
			-webkit-transform: translateX(-101%);
			-moz-transform: translateX(-101%);
			-ms-transform: translateX(-101%);
			-o-transform: translateX(-101%);
			transform: translateX(-101%);
		}
		100% {
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-ms-transform: translateX(0);
			-o-transform: translateX(0);
			transform: translateX(0);
		}
	}

	@keyframes slide-right-out {
		from {
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-ms-transform: translateX(0);
			-o-transform: translateX(0);
			transform: translateX(0);
		}

		to {
			-webkit-transform: translateX(-101%);
			-moz-transform: translateX(-101%);
			-ms-transform: translateX(-101%);
			-o-transform: translateX(-101%);
			transform: translateX(-101%);
		}
	}

     /* slide in from left */
  .slide-rightQuiz-enter-active {
		animation: slide-rightQuiz-in .4s ease-in-out forwards;
	}

	.slide-rightQuiz-leave-active {
		animation: slide-rightQuiz-out .4s ease-in-out forwards;
	}
   @keyframes slide-rightQuiz-in {
     0% {
			-webkit-transform: translateX(-101%);
			-moz-transform: translateX(-101%);
			-ms-transform: translateX(-101%);
			-o-transform: translateX(-101%);
			transform: translateX(-101%);
		}
		100% {
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-ms-transform: translateX(0);
			-o-transform: translateX(0);
			transform: translateX(0);
		}
	}

	@keyframes slide-rightQuiz-out {
		from {
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-ms-transform: translateX(0);
			-o-transform: translateX(0);
			transform: translateX(0);
		}

		to {
			-webkit-transform: translateX(-200%);
			-moz-transform: translateX(-200%);
			-ms-transform: translateX(-200%);
			-o-transform: translateX(-200%);
			transform: translateX(-200%);
		}
	}


  /* **** */
  .slide-rightMC-enter-active {
		animation: slide-rightMC-in .8s ease-in-out forwards;
	}

	.slide-rightMC-leave-active {
		animation: slide-rightMC-out .8s ease-in-out forwards;
	}
   @keyframes slide-rightMC-in {
     0% {
			-webkit-transform: translateX(300%);
			-moz-transform: translateX(300%);
			-ms-transform: translateX(300%);
			-o-transform: translateX(300%);
			transform: translateX(300%);
      opacity:0;
		}
		100% {
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-ms-transform: translateX(0);
			-o-transform: translateX(0);
			transform: translateX(0);
      opacity:1;
		}
	}

	@keyframes slide-rightMC-out {
		from {
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-ms-transform: translateX(0);
			-o-transform: translateX(0);
			transform: translateX(0);
      opacity:1;
		}

		to {
			-webkit-transform: translateX(-300%);
			-moz-transform: translateX(-300%);
			-ms-transform: translateX(-300%);
			-o-transform: translateX(-300%);
			transform: translateX(-300%);
      opacity:0;
		}
	}

   /* GROW */
   .grow1-enter-active {
		-webkit-animation: grow-in .8s ease-in-out backwards;
    -webkit-animation-delay: 0s;

		-moz-animation: grow-in .8s ease-in-out backwards;
    -moz-animation-delay: 0s;

		-ms-animation: grow-in .8s ease-in-out backwards;
    -ms-animation-delay: 0s;
		-o-animation: grow-in .8s ease-in-out backwards;
    -o-animation-delay: 0s;

		animation: grow-in .8s ease-in-out backwards;
    animation-delay: 0s;
	}

	.grow1-leave-active {
		-webkit-animation: grow-out .4s ease-in-out;
		-moz-animation: grow-out .4s ease-in-out;
		-ms-animation: grow-out .4s ease-in-out;
		-o-animation: grow-out .4s ease-in-out;
		animation: grow-out .4s ease-in-out;
	}
  .grow2-enter-active {
   
	-webkit-animation: grow-in .8s ease-in-out backwards;
  -webkit-animation-delay: 0.4s;

  -moz-animation: grow-in .8s ease-in-out backwards;
  -moz-animation-delay: 0.4s;

  -ms-animation: grow-in .8s ease-in-out backwards;
  -ms-animation-delay: 0.4s;

  -o-animation: grow-in .8s ease-in-out backwards;
  -o-animation-delay: 0.4s;

  animation: grow-in .8s ease-in-out backwards;
  animation-delay: 0.4s;
	}

	.grow2-leave-active {
		-webkit-animation: grow-out .4s ease-in-out;
		-moz-animation: grow-out .4s ease-in-out;
		-ms-animation: grow-out .4s ease-in-out;
		-o-animation: grow-out .4s ease-in-out;
		animation: grow-out .4s ease-in-out;
	}
  .grow3-enter-active {
		-moz-animation: grow-in .8s ease-in-out backwards;
    -moz-animation-delay: 0.8s;

    -webkit-animation: grow-in .8s ease-in-out backwards;
    -webkit-animation-delay: 0.8s;

    -ms-animation: grow-in .8s ease-in-out backwards;
    -ms-animation-delay: 0.8s;

    -o-animation: grow-in .8s ease-in-out backwards;
    -o-animation-delay: 0.8s;

    animation: grow-in .8s ease-in-out backwards;
    animation-delay: 0.8s;
    
	}

	.grow3-leave-active {
		animation: grow-out .4s ease-in-out;

    -webkit-animation: grow-out .4s ease-in-out;
    -moz-animation: grow-out .4s ease-in-out;
    -ms-animation: grow-out .4s ease-in-out;
    -o-animation: grow-out .4s ease-in-out;
    animation: grow-out .4s ease-in-out;
	}
   @keyframes grow-in {
     0% {
      transform: scaleX(1%) scaleY(1%);
		}
		70% {
      transform: scaleX(120%) scaleY(120%);
		}
    90% {
      transform: scaleX(90%) scaleY(90%);
		}
    100% {
      opacity:1;
      transform: scaleX(100%) scaleY(100%);
		}
	}

	@keyframes grow-out {
		from {
			transform: scaleX(100%) scaleY(100%);
		}

		to {
      transform: scaleX(10%) scaleY(10%);
		}
	}


  /* slide in up */
  .slide-up-quiz-enter-active {
		animation: slide-up-in 1s ease-in-out backwards;
    animation-delay: 0.5s;
	}

	.slide-up-quiz-leave-active {
		animation: slide-up-out 0.5s ease-in-out backwards;
	}
  .slide-up-enter-active {
		animation: slide-up-in 1s ease-in-out forwards;
	}

	.slide-up-leave-active {
		animation: slide-up-out 0.5s ease-in-out forwards;
	}
   @keyframes slide-up-in {
     0% {
       -webkit-transform: translateY(101%);
			-moz-transform: translateY(101%);
			-ms-transform: translateY(101%);
			-o-transform: translateY(101%);
			transform: translateY(101%);
			
		}
		100% {
			-webkit-transform: translateY(0);
			-moz-transform: translateY(0);
			-ms-transform: translateY(0);
			-o-transform: translateY(0);
			transform: translateY(0);
		}
	}

	@keyframes slide-up-out {
		from {
			-webkit-transform: translateY(0);
			-moz-transform: translateY(0);
			-ms-transform: translateY(0);
			-o-transform: translateY(0);
			transform: translateY(0);
		}

		to {
			-webkit-transform: translateY(101%);
			-moz-transform: translateY(101%);
			-ms-transform: translateY(101%);
			-o-transform: translateY(101%);
			transform: translateY(101%);
		}
	}

  /* slide up and up */
  .slide-up-up-enter-active {
		animation: slide-up-up-in .6s ease-in-out forwards;
	}

	.slide-up-up-leave-active {
		animation: slide-up-up-out 0.6s ease-in-out forwards;
	}
   @keyframes slide-up-up-in {
     0% {
       -webkit-transform: translateY(101%);
			-moz-transform: translateY(101%);
			-ms-transform: translateY(101%);
			-o-transform: translateY(101%);
			transform: translateY(101%);
			
		}
		100% {
			-webkit-transform: translateY(0);
			-moz-transform: translateY(0);
			-ms-transform: translateY(0);
			-o-transform: translateY(0);
			transform: translateY(0);
		}
	}

	@keyframes slide-up-up-out {
		from {
			-webkit-transform: translateY(0);
			-moz-transform: translateY(0);
			-ms-transform: translateY(0);
			-o-transform: translateY(0);
			transform: translateY(0);
		}

		to {
			-webkit-transform: translateY(-101%);
			-moz-transform: translateY(-101%);
			-ms-transform: translateY(-101%);
			-o-transform: translateY(-101%);
			transform: translateY(-101%);
		}
	}
</style>