<template>
  <div class="video">
     <!-- <video-background @ended="ended" :src="getSource" style="max-height: 400px; height: 100vh;" :muted="isMuted" :loop="getLoop"  > </video-background> -->
     <div class="playPause" :key='getPlayPauseClass' :class='getPlayPauseClass'><h1></h1></div>
     <video-background @click="goToMenu" class="vidBG" @ended="ended" :src="getSource" :muted="isMuted" :loop="getLoop"  > </video-background>
  </div>
</template>
<script>
import VideoBackground from 'vue-responsive-video-background-player'

export default {
  name: 'AppIntroVideo',
  data() {
        return {
          isPaused: false
        };
    },
  props: {
    data: {
        type: Object,
        required: true
    }
  },
  components: {
    VideoBackground
  },
  computed: {
      getPlayPauseClass() {
      var result= "";
      if(this.isPaused) {
        result =  "playing"
      } else {
        result = "paused"
      }
      return result;
    },
    getSource() {
      return this.data.src
    },
    isMuted() {
      return false
    },
    getLoop() {
      return false
    }
  },
  mounted() {
    this.emitter.emit("hideHeader");
  },
  methods:{
    playPause() {
      var vid = document.getElementById("fsVid"); 
      if(this.isPaused) {
        this.isPaused = false;
        vid.play();
      } else {
        this.isPaused = true;
        vid.pause();
      }
    },
    ended() {
      this.emitter.emit("goToMenu");
    },
    goToMenu() {
      //this.emitter.emit("goToMenu");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.video  {
  position:absolute;
  width:100%;
  height:100%;
  overflow:auto;
  overflow:hidden;
}

.vidBG {
  height:100%;
  width:auto;
  padding:0;
  margin:0;
}
.playPause {
  position:absolute;
  top:50%;
  left:50%;
  width:100px;
  height:100px;
  transform: translateX(-50%) translateY(-50%);
  padding-left:5px;
  z-index:1000000;
  border-radius:50%;
  background:rgba(0,0,0,.25);
  transition: all 2s;
  opacity:1;
  padding-top:5px;
  padding-bottom:5px;
  
}
.paused::after {
  color:#FFF;
  font-family: "FontAwesome";
  content: "\f04b";
  font-size:50px;
}
.playing::after {
  color:#FFF;
  font-family: "FontAwesome";
  content: "\f04c";
  font-size:50px;
}
.paused {
  animation-name: my-animation;
  animation-duration: .60s;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  opacity:0;
}
h1 {

}
.playing {
  animation-name: my-animation;
  animation-duration: .60s;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  opacity:0;
}

@keyframes my-animation {
  from {
    background-color: #000;
    opacity:1;
  }
  to {
    width:125px;
    height:125px;
  }
}
</style>
