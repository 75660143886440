<template>
  <div class="appheader">
    <h1>{{title}}</h1>
    <div class="progress">
      <div class="progressOutline"></div>
      <div class="progressFill" :style='getProgressStyle'>
      </div>
    </div>
    <button @click="goToMainMenu" class="close"></button>
  </div>
</template>

<script>
export default {
  name: 'AppHeaderContent',
  props: {
    title: {
      type:String,
      default:"..."
    },
    progress: {
      type:Number,
      default:0
    }
  },
  methods: {
    navBack() {
      this.emitter.emit("nav-back");
    },
    goToMainMenu() {
      this.emitter.emit("goToMenu");
    }
  },
  computed: {
    getProgressStyle() {
      return {
        width: this.progress+"%"
        }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  position:absolute;
  top:30%;
  transform: translateY(-50%);
  left:5%;

  font-size: calc(20px + 5 * ((100vw - 320px) / 680));

  color:#55B6F8;
  font-family:"Waterlily";
  margin:0;
}
.appheader {
  position:fixed;
  top:0;
  left:0;
  height:15%;
  max-height:130px;
  width:100%;
  z-index:999;
  padding-top:10px;
  padding-bottom:10px;
  background: url("../assets/images/stroke white loop.png") repeat-x center bottom;
  background-size: auto 110%;
  filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
  -webkit-filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
  -moz-filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
}
.button-back {
  position: absolute;
  top: 30%;
  left:2%;
  transform: translateY(-50%);
  font-size:1em;
  color:#3d3d3d;
  padding:0;
  font-weight:900;
}
.button-back::before {
  font-family: "FontAwesome";
  content: "\f053";
  padding-right:10px;
}

.button-menu {
  position: absolute;
  top: 30%;
  right:2%;
  transform: translateY(-50%);
  font-size:1em;
  color:#000;
  padding:0;
  font-weight:900;
}
.button-menu::after {
  font-family: "FontAwesome";
  content: "\f0c9";
  padding-left:10px;
}
.progress {
  position:absolute;
  left:5%;
  top:50%;
  width:75%;
  height:10%;
  border-radius:15px;
  background:#55B6F8;
}
.progressOutline {
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  border-radius:15px;
  background:rgb(110, 110, 110);
}
.progressFill {
  position:absolute;
  left:0;
  top:0;
  width:10%;
  height:100%;
  border-radius:15px;
  background:#55B6F8;
}
.close {
  display:block;
  float:right;
  font-size:2em;
  padding:0;
  margin-right:5%;
  font-weight:900;
  color:#818181;
}
.close::after {
  font-family: "FontAwesome";
  content: "\f0c9 ";   
}
</style>
