<template>
  <div class="credits">
    <div class='content'>
      <div class='close' @click='closeCredits'>{{"<"}}TERUG</div>
      <p class='spacer' ><b>Deze e-learning is ontwikkeld door Stichting Natuur &amp; Milieu in samenwerking met:</b><br><br>
      Remco Benjamin - <a href='https://www.remcobenjamin.nl' target='_blank'>www.remcobenjamin.nl</a><br><br>
      Emax films - <a href='https://www.emaxfilms.nl' target='_blank'>www.emaxfilms.nl</a><br><br></p>
      <p><b>De e-learning is mede mogelijk gemaakt door:</b><br><br>
      <a href='https://stichtingvivace.nl/' target='_blank'><img src="../assets/images/vivace-logo.jpg" alt=""><img src="" alt=""></a><br>
      <a href='https://www.cultuurfonds.nl/' target='_blank'><img src="../assets/images/pbcultuurfonds-logo.jpg" alt=""><img src="" alt=""></a><br><br>
      </p>
      <p>
        <b>Beeldmateriaal:</b><br>
        © blikonderwater: Arthur de Bruin - <a href='https://www.blikonderwater.nl' target='_blank'>www.blikonderwater.nl</a><br><br>
        © De Canicula: - <a href='https://www.decanicula.nl' target='_blank'>www.decanicula.nl </a><br><br>
        © Durable waters: Durable Water Solutions B.V. - <a href='https://www.durablewatersolutions.com' target='_blank'>www.durablewatersolutions.com</a><br><br>
        © Ernst’s OnderWaterWereld: Hoogheemraadschap van Delfland - <a href='www.hhdelfland.nl' target='_blank'>www.hhdelfland.nl</a><br><br>
      © Jibbe Kerstens - <a href='www.facebook.com/DroneHbeek' target='_blank'>www.facebook.com/DroneHbeek</a><br><br>
      © Leisurelands - <a href='www.leisurelands.nl' target='_blank'>www.leisurelands.nl</a><br><br>
      © Starsound Productions - <a href='www.starsoundproductions.com' target='_blank'>www.starsoundproductions.com</a><br><br>
      © WaterQi - <a href='www.waterqi.nl' target='_blank'>www.waterqi.nl</a><br><br>
      © MH WaterZaken  - <a href='www.mhwaterzaken.nl' target='_blank'>www.mhwaterzaken.nl</a><br><br>
      </p>
      <p>
        <b>Beeldmateriaal geciteerd:</b><br>
        © Arne1997: Arne, bron - <a href='https://www.youtube.com/watch?v=aouEJBpuvKw' target='_blank'>Youtube</a><br><br>
      © Christian Segonne: Christian Segonne, bron - <a href='https://www.youtube.com/watch?v=_TlBmQNfSc4' target='_blank'>Youtube</a><br><br>
        © Omroep West: Stichting Regionale Omroep West - <a href='http://www.omroepwest.nl' target='_blank'>http://www.omroepwest.nl</a>, bron:<a href='https://www.youtube.com/watch?v=7ifk8tqU3QQ' target='_blank'>Youtube</a><br><br>
        © OOG Groningen: Stichting Omroep Organisatie Groningen - <a href='http://www.oogtv.nl' target='_blank'>http://www.oogtv.nl</a>, bron:<a href='https://www.youtube.com/watch?v=dk5yDAKahas' target='_blank'>Youtube</a><br><br>
        © Prankster: John de Jong, Aart-Jan Laseur, Chris Mop - <a href='https://www.youtube.com/watch?v=kbuaRlITXmo' target='_blank'>Youtube</a><br><br>
        © RD: Reformatorisch Dagblad - <a href='http://www.rd.nl' target='_blank'>http://www.rd.nl</a>, bron:<a href='https://www.youtube.com/watch?v=1DmfoKWdDyA&t=178s' target='_blank'>Youtube</a><br><br>
        © Solomon David: Solomon David - <a href='https://www.youtube.com/watch?v=CMdawl05lMs' target='_blank'>Youtube</a><br><br>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppCredits',
  props: {
    data: {
        type:Object,
        required:true
    }
  },
  components: {
  },
  computed: {
  },
  mounted() {
  },
  methods:{
    closeCredits() {
      this.emitter.emit("goToMenu");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.close {
  position:absolute;
  z-index:999;
  font-size:2em;
  margin-top:2vh;
  margin-left:2vw;
  padding:10px 30px 10px 20px;
  color:#FFF;
  font-weight:900;
  font-family:"Soho";
  font-size: calc(16px + 4 * ((100vw - 320px) / 680));
  background: url("../assets/images/footer-button.png");
  background-size:100% 100%;
}
img {
  display:inline-block;
  max-width:150px;
}
.credits {
  position:absolute;
  top:0;
  width:100%;
  height:100%;
  overflow-x:hidden;
  overflow-y:scroll;
  -webkit-overflow-scrolling: touch;
}
.content {
  margin:0 auto;
  position:absolute;
  top:0;
  left:50%;
  transform: translateX(-50%);
  height:100%;
  width:80%;
  max-width:1000px;
}
p {
  font-family: "Soho";
  line-height:2em;
  width:100%;
  margin:0;
  padding:2% 10% 0 10%;
  background:#FFF;
}

.spacer { 
  padding-top:25%;
}
.header {
  position:fixed;
  top:0;
  left:0%;
  height:5%;
  width:100%;
  z-index:30;
  background:red;
}
</style>
