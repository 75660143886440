<template>
  <div :id="getID" class="hotspot" :style='getPosition'>
    <!-- Image on the layer that IS BEING magnified -->
    <div :id="getImageID" class="animatedGif" :style='getAnimation' alt=""></div>
    <!-- Image on the layer that WILL be magnified -->
    <div :id="getImageID" class="stillGif" :style='getImage' alt="" > </div>
    
  </div>
</template>

<script>
export default {
  name: 'AppHotspot',
  data() {
      return {
      }
  },
  methods:{
  },
  props: {
    destination: {
      type:Number,
      default:0
    },
    status: {
      type:Number,
      default:0
      // 0 = open, 1 = finished
    },
    xPos: {
        type: Number,
        default:0
    },
    yPos: {
        type: Number,
        default:0
    },
    image: {
        type: String,
        default: "assets/images/watermijt-still.gif"
    },
    imageCompleted: {
        type: String,
        default: "assets/images/watermijt-completed.png"
    },
    animation: {
      type: String,
      default: "assets/images/watermijt-animated.gif"
    },
    imageH: {
      default:25
    },
    imageW: {
      default:25
    },
    aniW: {
      default:25
    },
    aniH: {
      default:25
    }
  },
  computed: {
    getPosition() {
      var result = [
        "top:"+this.yPos+"%",
        "left:"+this.xPos+"%",
        "width:"+this.imageW+"%",
        "height:"+this.imageH+"%"]
      return result
    },
    getImage() {
      var result
      var img;
      if(this.status == 0){
        img = this.image;
      }
      if(this.status == 1){
        img = this.imageCompleted;
      }
      result = [
        
        "background: url("+img+") no-repeat center center",
        "background-size:contain"
      ]
      return result;
    },
    getAnimation() {
      var result
      result = [
        "background: url("+this.animation+") no-repeat center center",
        "background-size:contain"
      ]
      return result;
    },
    getID() {
      var result = "";
      result = "hotspot_"+this.xPos+"_"+this.yPos+""; 
      return result
    },
    getImageID() {
      var result = "";
      result = "hotspot_"+this.xPos+"_"+this.yPos+"_image"; 
      return result
    }
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hotspot {
  position:absolute;
  /* border:2px solid white; */
}

.element_to_magnify .animatedGif {
  position:absolute;
    opacity:0;
    width:100%;
    height:100%;
}
.magnified_element .animatedGif {
    position:absolute;
    opacity:1;
    width:100%;
    height:100%;
}





.element_to_magnify .stillGif {
    position:absolute;
    opacity:1;
    width:100%;
    height:100%;
}
.magnified_element .stillGif {
  position:absolute;
    opacity:0;
    width:100%;
    height:100%;
}
</style>
