<template>
  <div class="menu">
    <app-header-menu :totalScore='totalScore' ></app-header-menu>
     <transition name='slide-up' appear>
       <div class="chaptersContainer">
        <div class="chapters">   
            <app-menu-chapter v-for='(chapter, index) in data.chapters' :key='index' :id='index' :title='chapter.title' :targetSection='chapter.targetSection' :sectionStatus='getSectionStatus(index)' :progress='getProgress(chapter.targetSection)'></app-menu-chapter>
        </div>
      </div>
   </transition>
   <div class="footer">
     <button class='introButton' @click='goToIntro' > {{'< '}}INTRO</button>
     <button class='creditsButton' @click='goToCredits' >CREDITS</button>
   </div>
  </div>
</template>
<script>

import AppMenuChapter from './AppMenuChapter.vue'
import AppHeaderMenu from './AppHeaderMenu.vue'

export default {
  name: 'AppVideo',
   data() {
        return {
          isMuted: true,
          getLoop: true,
          getSource: "assets/video/drone background.mp4"
        };
    },
  props: {
    data: {
        type: Object,
        required: true
    },
    chapterProgressData: {
      default: []
    },
    totalScore: {
    }
  },
  components: {
    AppMenuChapter,
    AppHeaderMenu
  },
  computed: {
  },
  methods: {
    goToIntro() {
      this.emitter.emit("goToIntro")
    },
    goToCredits() {
      this.emitter.emit("goToCredits")
    },
    getSectionStatus(index) {
      // meopt dit verwijzen naar pages of niet?
      var result = this.chapterProgressData[index].status;
      return result
    },
    getProgress(index) {
      var result;
      var curSection = this.chapterProgressData[index].pages
      var maxProgress = curSection.length;
      var curSectionProgress = 0;
      for(var i=0;i<curSection.length;i++) {
        var curPageStatus = curSection[i].status;
        curSectionProgress += curPageStatus;
      }
      result = (curSectionProgress / maxProgress) * 100;
      return result;
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.explanation {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:20%;
}
.chaptersContainer {
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  height:90%;
}
.chapters {
  position:absolute;
  top:50%;
  left:50%;
/*let's say your ratio is x:y
     let x be 1293
    let y be 1920*/
    width: 100vw;
    /* height= 100/(x/y) */
    height:148.4912vw; 
    max-height: 75vh;
    /*Max-width =  100*(x/y) */
    max-width:67.344vh;
    transform:translateX(-50%) translateY(-50%);
}


.footer {
  position:fixed;
  bottom:0;
  left:50%;
  transform: translateX(-50%);
  height:10%;
  max-height:110px;
  width:100%;
  max-width:800px;
  z-index:999;
  padding-top:10px;
  padding-bottom:20px;
  /* background:url("../assets/images/stroke white.png") no-repeat top center;
  background-size: 100% 130%; */
  filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
  -webkit-filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
  -moz-filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
}

.introButton {
  position:absolute;
  left:5%;
  height:60%;
  top:55%;
  transform: translateY(-50%);
  padding-left:5%;
  padding-right:5%;
  padding-top:5px;
  font-family: "Soho";
  font-weight:900;
  font-size: calc(1em + 5 * ((100vw - 320px) / 680));
  background:url("../assets/images/footer-button.png") no-repeat top center;
  background-size:100% 100%;
   -webkit-filter: grayscale(100%) brightness(100%);
  filter: grayscale(100%) brightness(100%);
}

.creditsButton {
  position:absolute;
  right:5%;
  height:60%;
  top:55%;
  padding-top:5px;
  transform: translateY(-50%);
  padding-left:5%;
  padding-right:5%;
  font-size: calc(1em + 5 * ((100vw - 320px) / 680));
  font-family: "Soho";
  font-weight:900;
  background:url("../assets/images/footer-button.png") no-repeat top center;
  background-size:100% 100%;
   -webkit-filter: grayscale(100%) brightness(100%);
  filter: grayscale(100%) brightness(100%);
}

h3 {
  display:inline;
  float:left;
  font-size:1em;
  margin:0;
  margin-left:5%;
  color:#FFF;
  text-shadow: 2px 2px 3px rgba(0,0,0,0.5);
}
h4 {
  display:inline;
  float:right;
 
  font-size:1em;
  margin:0;
   margin-right:5%;
   color:#FFF;
   text-shadow: 2px 2px 3px rgba(0,0,0,0.5);
}
.menu {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  vertical-align: middle;

}


</style>