<template>
    <div class="rotateDevice">
        <h2 >Deze e-learning is geoptimaliseerd voor mobile devices in portraitmodus.</h2>
    </div>
</template>

<script>
// import { eventBus } from '../main'
export default {
    name: "AppRotateDevice",
    data() {
        return {};
    },
    props: {},
    methods: {},
    computed: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.rotateDevice {
        display:none;
    }
@media screen and (orientation: landscape) and (max-width: 812px) {
    .rotateDevice {
        display:block;
        position: absolute;
        color:#FFF;
        top:0;
        left:0;
        padding: 10%;
        z-index: 9999;
        width: 100%;
        height: 100vh;
        background:black;
    }
}
</style>