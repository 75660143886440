<template>
  <div class="appheader">
    <img class='star' src='../assets/images/star-yellow.png'><h2>{{totalScore}}/85:</h2><h1>{{getTitle}}</h1>
  </div>
</template>

<script>
export default {
  name: 'AppHeaderMenu',
  props: {
    title: {
      type:String,
      default:"KIES EEN HOOFDSTUK"
    },
    totalScore: {
      type:Number,
      default:0
    },
  },
  methods: {
  },
  computed: {
    getTitle() {
      var result;
      if(this.totalScore < 40) {
        result = "beginner"
      }
      if(this.totalScore >= 40 && this.totalScore < 65) {
        result = "leerling"
      }
      if(this.totalScore >= 65 && this.totalScore < 75) {
        result = "kenner"
      }
      if(this.totalScore >= 75 && this.totalScore < 81) {
        result = "expert"
      }
      if(this.totalScore >= 81) {
        result = "professor"
      }
      return result;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.appheader {
  position:absolute;
  display: flex;
  justify-content:center;
  align-items:center;
  top:0;
  left:50%;
  transform: translateX(-50%);
  height:12%;
  max-height:110px;
  width:100%;
  max-width:800px;
  z-index:999;
  background:url("../assets/images/stroke white.png") no-repeat center center;
  background-size: 100% 100%;
  filter: drop-shadow(1px 1px 12px rgba(0,0,0,0.24));
  -webkit-filter: drop-shadow(1px 1px 12px rgba(0,0,0,0.24));
  -moz-filter: drop-shadow(1px 1px 12px rgba(0,0,0,0.24));
}

.star {
 display: flex;
  justify-content: center;
  align-items: center;
  height:75%;
}
h1 {
 display: flex;
  font-size: calc(18px + 6 * ((100vw - 320px) / 680));
  color:#55B6F8;
  font-family:"Soho";
  margin:0;
  margin-left:2%;
  text-transform: uppercase;
  font-family: "Waterlily";
  
}
h2{
  display: flex;
  font-size: calc(24px + 6 * ((100vw - 320px) / 680));
  color:#FFDB28;
  font-family: "Waterlily";
  text-transform:uppercase;
  font-weight:900;
  margin:0;
}
</style>
