<template>
  <div class="apptest">
  </div>
</template>

<script>
export default {
  name: 'AppTest',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.apptest {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:darkgreen;
}
</style>
