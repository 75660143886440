<template>
  <div class="video" @click='playPause'>
    <transition name='fade'>
        <div v-if='loading' key='loadBar_1_vid' class="loadContainer">
            <div class='loading'></div>
        </div>
      </transition>
     <!-- <video-background @click="ended" class="vidBG" @ended="ended" :src="getSource" :muted="isMuted" :loop="getLoop"> </video-background> -->
      <div class="playPause" :key='getPlayPauseClass' :class='getPlayPauseClass'><h1></h1></div>
     <video id='fsVid' playsinline @ended="ended" preload="auto"> <source :src="getSource"> </video>
  </div>
</template>
<script>
export default {
  name: 'AppVideo',
   data() {
        return {
          isPaused: false,
          loading: true
        };
    },
  props: {
    data: {
        type:Object,
        required:true
    }
  },
  components: {
  },
  computed: {
    getPlayPauseClass() {
      var result= "";
      if(this.isPaused) {
        result =  "playing"
      } else {
        result = "paused"
      }
      return result;
    },
    getSource() {
      return this.data.src
    },
    isMuted() {
      return false
    },
    getLoop() {
      return false
    }
  },
  mounted() {
    setTimeout(this.startVid,1500);
  },
  methods:{
    startVid() {
      this.loading= false;
      var vid = document.getElementById("fsVid"); 
      vid.play();
      this.isPaused = false;
    },
    playPause() {
      var vid = document.getElementById("fsVid"); 
      if(this.isPaused) {
        this.isPaused = false;
        vid.play();
      } else {
        this.isPaused = true;
        vid.pause();
      }
    },
    ended() {
      this.emitter.emit("setCurrentPageScore", 1)
      this.emitter.emit("completeCurrentPage")
      this.emitter.emit("GoTo",{section:this.data.targetSection, page:this.data.targetPage});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  
  border: 10px solid rgba(85, 182, 248, 1);
  border-top-color: rgba(85, 182, 248, .25);
  animation: spin 2s infinite linear;
  z-index:9999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:#FFF;
    z-index:9999;
}
.video  {
  position:absolute;
  width:100%;
  height:100%;
  overflow:auto;
  overflow:hidden;
  background:#FFF;
  z-index:99999;
}
video { 
  padding:0;
  margin:0;
  background:#000;
  margin-left:-50%;
  width: 200%;
  height: 100%;
}
.playPause {
  position:absolute;
  top:50%;
  left:50%;
  width:100px;
  height:100px;
  transform: translateX(-50%) translateY(-50%);
  padding-left:5px;
  z-index:1000000;
  border-radius:50%;
  background:rgba(0,0,0,.25);
  transition: all 2s;
  opacity:1;
  padding-top:5px;
  padding-bottom:5px;
  
}
.paused::after {
  color:#FFF;
  font-family: "FontAwesome";
  content: "\f04b";
  font-size:50px;
}
.playing::after {
  color:#FFF;
  font-family: "FontAwesome";
  content: "\f04c";
  font-size:50px;
}
.paused {
  animation-name: my-animation;
  animation-duration: .60s;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  opacity:0;
}
h1 {

}
.playing {
  animation-name: my-animation;
  animation-duration: .60s;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  opacity:0;
}

@keyframes my-animation {
  from {
    background-color: #000;
    opacity:1;
  }
  to {
    width:125px;
    height:125px;
  }
}
</style>
