<template>
  <div class="dragDoubleTarget" @mousemove="mousemove" @mouseup="mouseUp" @touchend="touchEnd" @touchmove="touchMove" >
    <audio id="answer_correct_sound_1"  src="assets/audio/correct 1.mp3" preload="auto"></audio>
    <audio id="answer_correct_sound_2"  src="assets/audio/correct 2.mp3" preload="auto"></audio>
    <audio id="answer_correct_sound_3"  src="assets/audio/correct 3.mp3" preload="auto"></audio>
    <audio id="answer_incorrect"  src="assets/audio/incorrect.mp3" preload="auto"></audio>

    <transition  appear name="fade">
        <app-instructions v-if='showInstruction' :instructionSrc='this.data.instructionSrc'></app-instructions>
    </transition>

    <app-header-quiz :title='this.data.title' :progress='quizProgress'></app-header-quiz>
    <transition appear name='fade'>
      <div v-if='showStarScore' class="addScore">
          <transition appear name='grow1' > <img v-if='pointsToAdd >0' class="star"  key="star_1" src="../assets/images/star-yellow.png"></transition>
          <transition appear name='grow2'> <img   v-if='pointsToAdd >1' class="star" key="star_2" src="../assets/images/star-yellow.png"></transition>
          <transition appear name='grow3' > <img v-if='pointsToAdd >2' class="star" key="star_3" src="../assets/images/star-yellow.png"></transition>
        </div>      
     </transition>
    <div class="top" :class='getBackgroundSize("above")'>
      <div class="labelTop">GOEDE WATERKWALITEIT</div>
    </div>
    <div class="middle">
      <transition-group  name='slide-rightQuiz' appear>
        <h1 :key='0' v-if='!quizEnded'>{{getQuestionName}}</h1>
        <div :key='currentQuestion' v-if='!quizEnded' class="item" :style='getImage' :class='getIncorrectClass' @mousedown="mouseDown" @touchstart="touchStart">
        </div>
      </transition-group>
    </div>
    <div class="bottom" :class='getBackgroundSize("below")'>
      <div class="labelBottom">SLECHTE WATERKWALITEIT</div>
    </div>
  </div>

  <!-- SVG -->
 
</template>

<script>
import AppHeaderQuiz from './AppHeaderQuiz.vue'
import AppInstructions from './AppInstructions.vue'

export default {
  name: 'AppDragDoubleTarget',
  data() {
      return {
        dragTarget: null,
        dragStartX: 0,
        dragStartY: 0,
        isDragging: false,
        pos1: 0,
        pos2: 0,
        pos3: 0,
        pos4: 0,
        itemContainerRef: null,
        doFade: false,
        currentQuestion: 0,
        canAnswer:true,
        givenAnswer:0,
        showStarScore:false,
        score:0,
        nrQuestionsAnswered: 0,
        quizEnded: false,
        position: null,
        answeredIncorrect: false,
        pointsToAdd:3,
        canClick: true,
        questionPool: [],
        showInstruction: true
      }
  },
  components: {
    AppHeaderQuiz,
    AppInstructions
  },
  mounted() {
    var status = this.chapterProgressData[this.currentSection].pages[this.currentPage].status;
    if(status == 0) {
      this.canClick = false;
      this.showInstruction = true
    } else {
      this.canClick = true;
      this.showInstruction = false
    }
    
    this.emitter.on("hideInstruction", () => {
      this.showInstruction = false;
      this.canClick = true;
    });
    this.emitter.emit("setCurrentQuizScore", 0)
    this.randomizePool();
  },
  computed: {
    getIncorrectClass() {
      if(this.answeredIncorrect) {
        return ["incorrectRed"];
      } else {
        return ["noRed"]
      }
    },
    quizProgress() {
      var result = 0;
      result = (this.nrQuestionsAnswered / this.nrQuestions) * 100;
      return result;
    },
    nrQuestions() {
      return this.data.questions.length;
    },
    getQuestionName() {
      return this.data.questions[this.currentQuestion].name;
    },
    getImage() {
      var img;
      if (this.isDragging) {
        img = this.data.questions[this.currentQuestion].animation;
      } else {
        img = this.data.questions[this.currentQuestion].img;
      }
      return ["background: url("+img+") no-repeat center center;", "background-size: contain"]
    },
    centerY() {
      return window.innerHeight/2;
    },
    isDraggingClass() {
      if(this.isDragging) {
        return "dragClass"
      } else {
        return ""
      }
    }
  },
  props: {
    data: {
      type:Object,
      required: true
    },
    chapterProgressData: {

    },
    currentSection: {
    },
    currentPage: {

    }
  },
  methods: {
    randomizePool() {
      this.questionPool =  this.data.questions;
      this.questionPool =  this.questionPool.sort(() => Math.random() - 0.5);
    },
    enableClick() {
      this.canClick = true;
    },
    getBackgroundSize(bgPos) {
      var result = [""]
      if(this.position == bgPos) {
        result =  ["bgGrow"]
      }
      return result;
    },
    touchStart(e) {
      if(e != null && this.canClick) {
        this.dragTarget = e.target;

        this.dragStartX = this.dragTarget.offsetLeft
        this.dragStartY = this.dragTarget.offsetTop

        this.isDragging = true;

        this.pos3 = e.touches[0].clientX;
        this.pos4 = e.touches[0].clientY
      }
    },
    mouseDown(e) {
      if(e != null && this.canClick) {
      this.dragTarget = e.target;
      
      this.dragStartX = this.dragTarget.offsetLeft
      this.dragStartY = this.dragTarget.offsetTop
      this.isDragging = true;

      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      }
    },


    mousemove(e) {
      if(e != null && this.canClick) {
        if(this.isDragging) {
          this.pos1 = this.pos3 - e.clientX;
          this.pos2 = this.pos4 - e.clientY;
          this.pos3 = e.clientX;
          this.pos4 = e.clientY;
          this.dragTarget.style.top = (this.dragTarget.offsetTop - this.pos2) + "px";
          this.dragTarget.style.left = (this.dragTarget.offsetLeft - this.pos1) + "px";
          var aboveMargin = window.innerHeight * 0.08;
          var belowMargin = window.innerHeight * 0.25;
          this.position = "";
          if( this.pos4 > (this.centerY + belowMargin) ) {
              this.position = 'below'
            }
          if( this.pos4 < (this.centerY - aboveMargin) ) {
            this.position = 'above'
          }
        }
      }
    },
    touchMove(e) {
      if(e != null && this.canClick) {
        if(this.isDragging) {
          this.pos1 = this.pos3 - e.touches[0].clientX;
            this.pos2 = this.pos4 -e.touches[0].clientY;
            this.pos3 = e.touches[0].clientX;
            this.pos4 = e.touches[0].clientY;
            this.dragTarget.style.top = (this.dragTarget.offsetTop - this.pos2) + "px";
            this.dragTarget.style.left = (this.dragTarget.offsetLeft - this.pos1) + "px";
            var aboveMargin = window.innerHeight * 0.08;
          var belowMargin = window.innerHeight * 0.25;
          this.position = "";
          if( this.pos4 > (this.centerY + belowMargin) ) {
              this.position = 'below'
          }
          if( this.pos4 < (this.centerY - aboveMargin) ) {
            this.position = 'above'
          }
        }
      }
    },
    mouseUp(e) {
      if(e != null && this.canClick && this.isDragging) {
        this.isDragging = false;
        if(e.clientY > this.centerY) {
          this.givenAnswer = 1;
        }
        if(e.clientY < this.centerY) {
          this.givenAnswer = 0;
        }
        if(this.position == "") {
            this.resetDragPosition();
        } else {
            this.checkAnswer();
        }
      } else {
        //
      }
    },
    touchEnd(e) {
      if(e != null && this.canClick && this.isDragging) {
        this.isDragging = false;
        if( this.pos4 > this.centerY) {
          this.givenAnswer = 1;
        }
        if(this.pos4 < this.centerY) {
          this.givenAnswer = 0;
        }
        if(this.position == "") {
            this.resetDragPosition();
        } else {
            this.checkAnswer();
        }
      } else {
        //
      }
    },
    resetDragPosition() {
      this.dragTarget.style.top = this.dragStartY + "px";
      this.dragTarget.style.left = this.dragStartX + "px";
      this.answeredIncorrect = false;
    },
    
    checkAnswer(e) {
      this.canClick = false;
      var correctAnswer = this.data.questions[this.currentQuestion].correct;
      if(this.givenAnswer == correctAnswer) {
        this.answeredIncorrect = false;
        this.addPoints();
      } else {
        this.answeredIncorrect = true;
        this.pointsToAdd--;
        if(this.pointsToAdd < 1) {
          this.pointsToAdd = 1;
        }
        document.getElementById("answer_incorrect").play();
        setTimeout(this.resetDragPosition, 800)
        setTimeout(this.enableClick, 900)
      }
    },
    showIncorrect() {
      //
    },
    addPoints() {
      this.showStarScore = true;
      this.score += this.pointsToAdd;
      if(this.pointsToAdd == 3) {
        document.getElementById("answer_correct_sound_3").play();
      }
      if(this.pointsToAdd == 2) {
        document.getElementById("answer_correct_sound_2").play();
      }
      if(this.pointsToAdd == 1) {
        document.getElementById("answer_correct_sound_1").play();
      }
      setTimeout(this.hideAddPoint, 2000);
      this.emitter.emit("setCurrentQuizScore", this.score)
    },
    hideAddPoint() {
      this.showStarScore = false;
      setTimeout(this.nextQuestion, 250);
      setTimeout(this.enableClick, 900)
    },
    nextQuestion() {
      this.pointsToAdd = 3;
      this.position = null;
      this.nrQuestionsAnswered++;
      if(this.currentQuestion+1 < this.nrQuestions) {
        this.currentQuestion++;
      } else {
        this.quizEnded = true;
        this.emitter.emit("completeCurrentPage");
        this.emitter.emit("setCurrentPageScore", this.score);
        this.emitter.emit("goToNextPage");
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.labelTop, .labelBottom {
  position:absolute;
  width:100%;
  left:50%;
  top:50%;
  transform: translateX(-50%) translateY(-50%);
  font-family: "Waterlily";
  font-weight:900;
  padding:2% 10% 2% 10%;
  font-size: calc(14px + 10 * ((100vw - 300px) / (1600 - 300)));
  color:#FFF;

}

.labelTop{
  margin-top:2%;
}

.labelBottom {

}


.item {
  position:absolute;
  top:25%;
  left:25%;
  height:50%;
  width:50%;
  background-size:contain;
}
.dragDoubleTarget {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:#c2cad9;
  z-index:99999;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.top {
  position:absolute;
  top:0;
  left:0;
  height:46%;
  width:100%;
  background: url("../assets/images/Water-schoon.jpg") repeat-x center bottom;
  background-size:auto 100%;
  z-index:1;
  transition: all 0.5s;
}
.bottom {
  position:absolute;
  bottom:0;
  left:0;
  height:35%;
  width:100%;
  background: url("../assets/images/Water-vies.jpg") repeat-x center bottom;
  background-size:auto 100%;
  z-index:1;
  transition: all 0.5s;
}
.middle {
  display:flex;
  position:relative;
  justify-content: center;
  top:55%;
  transform: translateY(-50%);
  width:100%;
  height:35%;
  padding:0;
  margin:0;
  background:url("../assets/images/white loop bottom straight.png") repeat-x center center;
  background-size: auto 100%;
  z-index:3;
  filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
  -webkit-filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
  -moz-filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
}
h1 {
  position:absolute;
  top:5%;
  font-family: "Waterlily";
  text-transform: uppercase;
  font-size: calc(20px + 10 * ((100vw - 320px) / 680));
  color:#55B6F8;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.dragItem {
  position:absolute;
  top:50%;
  left:50%;
  height:35%;
  width:auto;
  transform: translateX(-50%) translateY(-50%);
}
.bgGrow {
  background-size: auto 125%;
  transition: all 0.5s;
}
.incorrectRed {
  filter: invert(15%) sepia(100%) saturate(800%) hue-rotate(355deg) brightness(60%) contrast(300%);
    transition: all .3s;
}
.noRed {
  filter: none;
}

.addScore {
  position:absolute;
  left:0%;
  top:0%;
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:4;
  background:rgba(0,0,0,0.3)
}

.addScore img {
  width:33%;
  max-width:760px;
  height:auto;
}
</style>
