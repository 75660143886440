<template>
  <div class="chapterStart">
    <video-background class="vidBG" @ended="ended" :src="getSource" :muted="isMuted" :loop="getLoop"  > </video-background>
     <div class="content">
      <div class="title"><h1>{{getTitle}}</h1></div>
      <button @click="startChapter" class="btn-start">Start</button>
    </div>
  </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
export default {
  name: 'AppChapterStart',
  components: {
    VideoBackground
  },
  props: {
    data: {
        type: Object,
        required: true
    }
  },
  mounted() {
    this.emitter.emit("hideHeader");
  },
  methods: {
    startChapter() {
      this.emitter.emit("start-chapter");
    }
  },
  computed: {
    getTitle() {
      return this.data.title
    },
   getSource() {
      return this.data.src
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vidBG {
  height:100%;
  width:auto;
  padding:0;
  margin:0;
}
.title {
  display:block;
  position:absolute;
  top:20%;
  left:0;
  width:100%;
  text-align: center;  
}
h1 {
  /* -webkit-animation: squiggly-anim 0.34s linear infinite;
   animation: squiggly-anim 0.34s linear infinite; */
}
h1{
  display:inline;
  font-size:3.25em;
  padding:.2em .3em .1em .3em;
  line-height:1.6em;
  color:#343433;
  font-family: "N&M Utrecht";
  font-weight:900;
  background:#FFF;
}

.btn-start{
  transition:all .5s;
  display:inline-block;
  color:#12a5b7;
  background:#FFF;
  font-family:"Soho";
  font-weight:300;
  font-size:2em;
  line-height:0;
  padding:1em 1em .9em 1em;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.0);
}
.btn-start:hover{
  transition:all .5s ease-in;
  color:#FFF;
  background:rgba(255,255,255,.3);
}

.chapterStart {
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  text-align:center;
  /* background: no-repeat bottom center url("../assets/images/Start background.png"); */
  background-size:cover;
}
</style>
