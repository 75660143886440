<template>
  <div id="navmap" class="navmap">
    <div class="map" :style="getBackground">
      <div class="hotspots">
        <app-hotspot
            v-for="(hotspot, index) in data.hotspots"
            :key="index"
            :xPos="hotspot.x"
            :yPos="hotspot.y"
            :destination="getDestination(index)"
            :status="getStatus(index)"
        ></app-hotspot>
      </div>
    </div>
  </div>
</template>

<script>
import AppHotspot from './AppHotspot.vue'

export default {
  name: 'AppNavMap',
   components: {
    AppHotspot
  },
  props: {
      data: {
          type: Object,
          required: true
      },
      currentSection: {
        type:Number,
        required: true
      },
      chapterProgress: {
        type:Array,
        required: true
      }
  },
  computed: {
    getBackground() {
      return "background:url('" +this.data.map.src + "')no-repeat center center;"
    }
  },
  mounted() {
    this.emitter.emit("showHeader");
  },
  methods:{
    getDestination(index) {
      var result = Number(2 + index);
      return result
    },
    getStatus(index) {
      var result = this.chapterProgress[this.currentSection][2 +index].status;
      return result
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.navmap {
  position:fixed;
  width:100%;
  height:100%;
  overflow:auto;
}

.map {
  position:absolute;
  top:0;
  left:0;
  width:1920px;
  height:1080px;
}

.circleHotspot-1 {
  position:absolute;
  left:20%;
  top:20%;
  z-index:998;
  width:100px; 
  height:100px;
}
@keyframes hotspotLoop{
  0%{background-position-x:600px;
    }
}

.hotspotgif {
  position:absolute;
  left:30%;
  top:30%;
  width:200px;
  height:200px;
  background-size:contain;
}
.circleHotspot-2 {
  position:absolute;
  left:60%;
  top:20%;
  z-index:998;
  width:200px; 
  height:200px;
}
.ani-1 {
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  animation:hotspotLoop 0.5s steps(6,end) infinite;
  animation-delay: -0s;
  background:url("../assets/images/highlight-animation-1.png");
}
.ani-2 {
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  animation:hotspotLoop 0.5s steps(6,end) infinite;
  animation-delay: -0.4s;
  background:url("../assets/images/highlight-animation-1.png");
  background-size:1200px 200px;
}
@keyframes hotspotLoop{
  0% { background-position-x:1200px; }
}
</style>
