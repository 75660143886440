<template>
  <div class="appMultipleChoice">
    <audio id="answer_correct_sound_1"  src="assets/audio/correct 1.mp3" preload="auto"></audio>
    <audio id="answer_correct_sound_2"  src="assets/audio/correct 2.mp3" preload="auto"></audio>
    <audio id="answer_correct_sound_3"  src="assets/audio/correct 3.mp3" preload="auto"></audio>
    <audio id="answer_incorrect"  src="assets/audio/incorrect.mp3" preload="auto"></audio>
    <!-- <app-header-quiz :title='this.data.title' :progress='quizProgress'></app-header-quiz> -->
    <app-header-quiz :title='this.data.title' :progress='quizProgress'></app-header-quiz>
    <transition appear name='fade'>
      <div v-if='showStarScore' class="addScore">
          <transition appear name='grow1' > <img v-if='pointsToAdd >0' class="star"  key="star_1" src="../assets/images/star-yellow.png"></transition>
          <transition appear name='grow2'> <img   v-if='pointsToAdd >1' class="star" key="star_2" src="../assets/images/star-yellow.png"></transition>
          <transition appear name='grow3' > <img v-if='pointsToAdd >2' class="star" key="star_3" src="../assets/images/star-yellow.png"></transition>
        </div>      
     </transition>
    <!-- <div class="top" :class='getBackgroundSize("above")'></div>
    <div class="middle"> -->
      <!-- <transition-group  name='slide-rightQuiz' appear>
        <h1 :key='0' v-if='!quizEnded'>{{getQuestionName}}</h1>
        <div :key='currentQuestion' v-if='!quizEnded' class="item" :style='getImage' :class='getIncorrectClass' @mousedown="mouseDown" @touchstart="touchStart">
        </div>
      </transition-group> -->
      <transition appear name='fade'>
        <div class="questionImage" :style='getQuestionImage' :key='"bgImage_"+this.currentQuestion'></div>
      </transition>
      <div class="answers">
          <transition name='slide-rightMC' appear mode='out-in'>
           <div :key='"answers_"+this.currentQuestion'>
              <div v-for="(answer, index) in answerPool" :key="answer.body+'_'+index" @click='giveAnswer(index)' class="answer" :style='getBackground(index)' :class='getAnswerFeedbackClass(index)'>{{answerPrefix(index)}}{{answer.body}}
              </div>
            </div>
        </transition>
      </div>
    </div>

  <!-- SVG -->
 
</template>

<script>
import AppHeaderQuiz from './AppHeaderQuiz.vue'

export default {
  name: 'appMultipleChoice',
  data() {
      return {
        isAnswered: false,
        givenAnswer: null,
        isAnsweredCorrect: false,
        showStarScore: false,
        score: 0,
        pointsToAdd: 3,
        nrQuestionsAnswered: 0,
        canClick: true,
        currentQuestion: 0,
        questionPool: [],
        answerPool: []
      }
  },
  components: {
    AppHeaderQuiz
  },
  computed: {
    getQuestionImage() {
       return "background:url("+this.data.questions[this.currentQuestion].image+") no-repeat top center; background-size:contain;"
    },
    quizProgress() {
      var result = 0;
      result = (this.nrQuestionsAnswered / this.nrQuestions) * 100;
      return result;
    },
    getQuestionName() {
      return this.data.questions[this.currentQuestion].name;
    },
        nrQuestions() {
      return this.data.questions.length;
    }
  },
  props: {
    data: {
      type:Object,
      required: true
    }
  },
  methods: {
    randomizePool() {
      this.questionPool =  this.data.questions;
      this.questionPool =  this.questionPool.sort(() => Math.random() - 0.5);
      this.randomizeAnswers();
    },

    randomizeAnswers() {
      this.answerPool =  this.data.questions[this.currentQuestion].answers;
      this.answerPool =  this.answerPool.sort(() => Math.random() - 0.5);
      console.log("********")
      console.log(this.answerPool);
    },
    resetAnswers() {
      this.isAnswered = false;
      this.givenAnswer = null;
      this.isAnsweredCorrect = false;
      this.canClick = true;
    },
    giveAnswer(index) {
      this.resetAnswers();
      this.canClick = false;
      this.givenAnswer = index;
      this.isAnswered = true;
      if(this.data.questions[this.currentQuestion].answers[index].correct == true) {
        this.isAnsweredCorrect = true;
        this.addPoints();
      } else {
        this.pointsToAdd--;
        if(this.pointsToAdd < 1) {
          this.pointsToAdd = 1;
        }
        this.isAnsweredCorrect = false;
        document.getElementById("answer_incorrect").play();
      }
    },
    getBackground(index) {
      var result ="background:url('assets/images/";
      if(this.data.questions[0].answers[index].body.length > 4) {
        if(this.givenAnswer == index) {
          if(this.isAnsweredCorrect) {
            result += "answer 2 correct.png') "
          } else {
            result += "answer 2 incorrect.png') "
          }
        } else {
          result += "answer 2.png') "
        }
      } else {
        if(this.givenAnswer == index) {
          if(this.isAnsweredCorrect) {
            result += "answer 1 correct.png') "
          } else {
            result += "answer 1 incorrect.png') "
          }
        } else {
          result += "answer 1.png') "
        }
      }
      result += "no-repeat; background-size: 100% 100%;"
      return result
    },
    getAnswerFeedbackClass(index) {
      var result = "";
      if(this.givenAnswer == index) {
        if(this.isAnsweredCorrect) {
          result = "correctGreen"
        } else {
          result = "incorrectRed"
        }
      }
      return result;
    },
    answerPrefix(index) {
      var result;
      switch(index) {
        case 0:
          result = "A) "
          break;
        case 1:
          result = "B) "
          break;
        case 2:
          result = "C) "
          break;
        case 3:
          result = "D) "
          break;
        default:
          result = "A) "
        } 
        return result;
    },
    addPoints() {
      this.showStarScore = true;
      this.score += this.pointsToAdd;
      if(this.pointsToAdd == 3) {
        document.getElementById("answer_correct_sound_3").play();
      }
      if(this.pointsToAdd == 2) {
        document.getElementById("answer_correct_sound_2").play();
      }
      if(this.pointsToAdd == 1) {
        document.getElementById("answer_correct_sound_1").play();
      }
      this.emitter.emit("setCurrentQuizScore", this.score)
      setTimeout(this.hideAddPoint, 2000);
    },
    hideAddPoint() {
      this.showStarScore = false;
      setTimeout(this.resetAnswers, 250);
      setTimeout(this.nextQuestion, 250);
      setTimeout(this.enableClick, 400)
    },
    nextQuestion() {
      this.pointsToAdd = 3;
      this.position = null;
      this.nrQuestionsAnswered++;
      if(this.currentQuestion+1 < this.nrQuestions) {
        this.currentQuestion++;
        this.randomizeAnswers();
      } else {
        this.quizEnded = true;
        this.emitter.emit("completeCurrentPage");
        this.emitter.emit("setCurrentPageScore", this.score);
        this.emitter.emit("goToNextPage");
      }
    }
  },
  mounted() {
    this.emitter.emit("setCurrentQuizScore", 0)
    this.randomizePool();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.appMultipleChoice {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:#FFFF;
  z-index:99999;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.questionImage {
  position:absolute;
  top:100px;
  width:100%;
  height:85%;
  padding:0;
  margin:0;
  background:aqua;

}

.answers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-end;
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  height:30%;
  z-index:1;
  transition: all 0.5s;
  padding-top:40px;
  padding-bottom:30px;

}

.answer {

  font-family: "Waterlily";
  text-transform: uppercase;
  font-size: calc(1em + 4 * ((100vw - 320px) / 680));
  padding:.75em 2.5em .75em 2.5em;
  min-width:60%;
  color:#FFF;
  cursor: pointer;
  text-align:left;
  
}
.incorrect {

}
.correct {

}

.addScore {
  position:absolute;
  left:0%;
  top:0%;
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:4;
  background:rgba(0,0,0,0.3)
}

.addScore img {
  width:33%;
  max-width:400px;
  height:auto;
}
.noRed {
  filter: none;
}
</style>
