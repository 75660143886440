<template>
  <div class="nav" id='myComponentDiv'>
      <transition name='fade'>
        <div v-if='loading' key='loadBar_1' class="loadContainer">
            <div class='loading'></div>
        </div>
      </transition>
      <transition  appear name="fade">
        <app-instructions v-if='showInstruction' :instructionFirstTime='instructionFirstTime' :instructionSrc='getInstructionSrc'></app-instructions>
    </transition>
      <audio id="audio_intro_0"  src="assets/audio/watertypen-intro.mp3" @ended='audioEnded' preload="auto"></audio>
      <audio id="audio_intro_1"  src="assets/audio/waterkwaliteit-intro.mp3" @ended='audioEnded' preload="auto"></audio>
      <audio id="audio_intro_2"  src="assets/audio/waterdieren-intro.mp3" @ended='audioEnded' preload="auto"></audio>
      <audio id="audio_intro_3"  src="assets/audio/waterplanten-intro.mp3" @ended='audioEnded' preload="auto"></audio>
      <audio id="audio_intro_4"  src="assets/audio/oorzaken-intro.mp3" @ended='audioEnded' preload="auto"></audio>

      <div class="topBackgroundRepeat"></div>
        <div class="bottomBackgroundRepeat"></div>
      <transition  appear name="slide-down" mode="out-in">
        <app-header-content v-if="showHeader" :progress="getCurrentSectionProgress" :title='getChapterTitle'></app-header-content>
      </transition>
    <transition appear name="fade">
        <div class="navContainer" id='navContainer'>
            <div v-if="showHeader" id="window" class="magnify" data-magnified-zone=".mg_zone" >

                <div class="magnify_glass" :class='getMagClass' id="magGlas">
                    <img class="magGlasImage" src="../assets/images/magglass.png" id='magGlasImage' alt="">
                    <div class="mg_ring"  @click='clickHotspot' @drag='clickHotspot' id='mg_ring' :class='getRingClass' ></div>
                    <div class="mg_zone" ></div>
                </div>
                
                <div class = "element_to_magnify">
                    <div class="navMap" :style='getBackground' alt=""></div>
                    <app-hotspot v-for='(hotspot, index) in data.hotspots' :key='getHotspotKey(index)' :xPos='hotspot.x' :yPos='hotspot.y' :isTouching="curTouch" :status='getStatus(data.hotspots[index].target)' :id="getHotspotKey(index)"
                    :image='hotspot.image' :animation='hotspot.animation' :imageCompleted='hotspot.imageCompleted' :imageW='hotspot.imageW' :imageH='hotspot.imageH' :aniW='hotspot.aniW' :aniH='hotspot.aniH'
                    ></app-hotspot>
                </div>
            </div>
        </div>
    </transition>

<!-- 
    <transition appear name="fade">
        <app-video v-if="showVideo" class="vidBG" src="assets/video/3_waterkwaliteit_-_2_kroos_en_veel_drijfplanten (Original).mp4"> </app-video>
    </transition> -->

    <transition appear name="slide-up-quiz" mode="out-in">
        <div class="footer" :class="getQuizStatusClass"  v-if="showFooter">
        <!-- <div class="footer" :class="getQuizStatusClass"> -->
            <button class='buttonPrev buttonUnlocked' v-if='showPrevButton' @click="prevPage"> {{"<"}} TERUG</button >
            <button class='buttonBig' v-if='this.hasQuiz' @click="nextPage">START QUIZ!</button >
            <button class='buttonNext' v-if='showNextButton' @click="nextPage" :class='getNextButtonClass'>VOLGENDE {{">"}}</button >
        </div>
    </transition>
</div>      
</template>

<script>
import AppHeaderContent from './AppHeaderContent.vue'
import AppVideo from './AppVideo.vue'
import AppHotspot from './AppHotspot.vue'
import AppInstructions from './AppInstructions.vue'

export default {
  name: 'AppNavMagnifier',
   components: {
       AppHeaderContent,
       AppVideo,
       AppHotspot,
       AppInstructions
  },
  data() {
      return {
          isTouching: false,
          showHeader: true,
          showVideo: false,
          curTouch: null,
          collisionInterval: setInterval,
          hotSpotObjectArray: [],
          firstTouch: false,
          touchID: null,
          collisionTargets: [],
          introAudio: null,
          showInstruction: false,
          magInitialized: false,
          loading: true
      }
  },
  props: {
      data: {
          type:Object,
          required:true
      },
      currentSection: {
          default:0
      },
      currentPage: {
          default:0
      },
      chapterProgressData: {
          default: []
      },
      instructionFirstTime: {

      }
  },
  methods: {
      startLoad(){
        var img = new Image();
        img.src = this.data.map.src;
        img.addEventListener("load", this.doneLoading);
    },
    doneLoading() {
        setTimeout(this.initialize, 500)
    },
    initialize() {
        this.loading = false;
        this.emitter.on("hideInstruction", () => {
            this.showInstruction = false;
            this.initializeMagnifier();
        });

        if(this.getStatus(this.currentPage) == 0) {
            this.playIntro();
        } else {
            this.initializeMagnifier();
        }
        this.initializeHotspotObjectAray();  
    },

    playIntro() {
        if(this.getStatus(this.currentPage) == 0) { 
            if(document.getElementById(this.data.audio) != null) {
                this.introAudio = document.getElementById(this.data.audio);
                this.introAudio.play();
            } else {
                this.initializeMagnifier();
            }
        } else {
            this.initializeMagnifier();
        }
    },
    audioEnded() {
        this.emitter.emit("completeCurrentPage");
        if(this.instructionFirstTime) {
        this.showInstruction = true;
        } else {
            this.initializeMagnifier();
        }
        
    },
    stopIntro() {
        this.introAudio.stop();
    },
    getType(id) {
        return this.data.hotspots[id].type
    },

    initializeHotspotObjectAray() {
        for(var i=0;i<this.data.hotspots.length;i++) {
            var curID = this.getHotspotKey(i);
            var curObject = {id:curID, collision:false}
            this.hotSpotObjectArray.push(curObject)
        }
        // this.hotSpotObjectArray: [
        // {id:"hotspot_10_60", collision:false},
        // {id:"hotspot_50_80", collision:false},
        // ]
    },
    getHotspotKey(id) {
          var result;
          var ref = this.data.hotspots[id]
          result = ref.x+"_"+ref.y+"_"+ref.target;
          return result;
    },
    getStatus(index) {
        var result = this.chapterProgressData[this.currentSection].pages[index].status
        return result;
    },
    nextPage() {
        // if(this.getCurrentSectionProgress == 100) {
            // this.emitter.emit("goToPage", this.chapterProgressData[this.currentSection].length-2);
            this.emitter.emit("goToPage", this.data.target);
        // }
    },
    prevPage() {
        // if(this.getCurrentSectionProgress == 100) {
            // this.emitter.emit("goToPage", this.chapterProgressData[this.currentSection].length-2);
            this.emitter.emit("goToPage", this.currentPage-1);
        // }
    },
    clickHotspot() {

          if(this.isTouching) {
              var a = this.data.hotspots[this.touchID];
              this.emitter.emit("goToPage", a.target);
          } else {
              //
          }
      },
      changeHeader() {
        this.destroyMagnifier();
        this.showHeader = false;
        this.showFooter = false;
        this.showVideo = true;
      },
      checkCollision() {
          window.jQuery (".magnify").data("jfMagnify").scaleMe(2);
        var isCollision = false;

        for(var i =0;i<this.hotSpotObjectArray.length;i++) {
            var curID = this.hotSpotObjectArray[i].id;
            var div1 = document.getElementById("magGlas");
            var div2 = document.getElementById(curID);
            var result;
            var offset = 30;
            var x1 = div1.offsetLeft;
            var y1 = div1.offsetTop;
            var h1 = div1.offsetHeight
            var w1 = div1.offsetWidth;
            var b1 = y1 + h1;
            var r1 = x1 + w1;
            var x2 = div2.offsetLeft + 35;
            var y2 = div2.offsetTop + 35;
            var h2 = div2.offsetHeight- (offset*2);
            var w2 = div2.offsetWidth - (offset*2);
            var b2 = y2 + h2;
            var r2 = x2 + w2;

            if (b1 < y2 || y1 > b2 || r1 < x2 || x1 > r2) {
                result = false;
            } else {
                result = true;
            }
            if(result == true) {
                isCollision = true;
                this.isTouching = true;
                this.touchID = i;
                this.curTouch = div2;
                i = this.hotSpotObjectArray.length+999;
            }
        }
        if(isCollision == false) {
            this.isTouching = false;
            this.curTouch = null;
        }
    },
    initializeMagnifier() {
        this.collisionInterval = setInterval(this.checkCollision, 100)
        if (window.jQuery) {  
            // jQuery is loaded  
                var scaleNum = 2;
            
                window.jQuery (".magnify").jfMagnify();
                window.jQuery (".magnify").data("jfMagnify").scaleMe(2);

                    window.jQuery ('.magnify_glass').animate({
                        'top': this.data.startLoc.y+"%",
                        'left': this.data.startLoc.x+"%"
                        },{
                        duration: 2000, 
                        progress: function(){
                            if(window.jQuery (".magnify").data("jfMagnify")) {
                                window.jQuery (".magnify").data("jfMagnify").update();
                            }
                        }, 
                        easing: "easeOutElastic"
                    });
                     window.jQuery (".magnify").data("jfMagnify").update();
                     this.magInitialized = true;

                    // window.jQuery ('.magnify_glass').click(function() {
                    //     alert( "Click on mag glas" );     
                    //     alert(window.app.$refs)                  
                    // });
                    
                    // var el = document.getElementById('mg_ring');
                    // // el.addEventListener("click", function(){ alert("magnify_glass"); }); 
                    // el.addEventListener("click", this.testMethod); 

                    // var tel = document.getElementById('magGlasImage');
                    // tel.addEventListener("click", this.clickHotspotFromImage); 
        } else {
            // jQuery is not loaded
            //alert("Doesn't Work");
        }
       
    },
    destroyMagnifier() {
        clearInterval(this.collisionInterval);
        if(window.jQuery (".magnify").data("jfMagnify")) {
            window.jQuery (".magnify").data("jfMagnify").destroy();
        }
    }
  },
  computed: {

      getMagClass() {
          var result ="hideMagGas";
          if(this.magInitialized) {
              result = ""
          }
          return result
      },
      getInstructionSrc() {
          return this.data.instructionSrc;
      },
      showPrevButton() {
          var result = false;
          if(this.currentPage > 0 ) {
              result = true;
          }
          return result
      },
      showNextButton() {
          var result = false;
          if(!this.hasQuiz) {
            var curSection = this.chapterProgressData[this.currentSection].pages
            var totalPages = curSection.length-1;
            if(this.hasQuiz) {
                totalPages--;
            }
            if(this.currentPage < totalPages) {
                result = true;
            }
          }
          return result
      },
      nextText() {
          var result = "..."
          if(this.hasQuiz== false) {
              result = "VOLGENDE!"
          } 
          if(this.hasQuiz) {
              result = "START QUIZ!"
          }
          return result;
      },
      hasQuiz() {
          return this.data.hasQuiz;
      },
      getNextButtonClass() {
          var result = "buttonLocked";
          if(this.getCurrentSectionProgress == 100) {
            result = "buttonUnlocked";
          }
          return result;
      },
      showFooter() {
        var result = false;
        if(this.getCurrentSectionProgress == 100) {
            result = true;
          }
        // if(!this.hasQuiz) {
        //     result = true;
        // }
        return result;
      },
      getRingClass() {
          var result ="";
          if(this.isTouching) { 
              if(this.getType(this.touchID) == 0) {
                result = ["ringCollisionGreen"]
              } else {
                  result = ["ringCollisionRed"]
              }
              
          }
          return result;
      },
      getChapterTitle() {
          return this.data.title;
      },
      getBackground() {
          return [
             [
                 "background: url(" + this.data.map.src + ") no-repeat center center;",
                 "background-size: auto 110%"

             ]
          ]
      },
      getQuizStatusClass() {
          if(this.getCurrentSectionProgress == 100) {
              return "unlocked"
          } else {
              return "unlocked"
          }
      },
      getCurrentSectionProgress() {
        var result =0;
        // if(this.chapterProgressData.length !=0) {
        //   var curSection = this.chapterProgressData[this.currentSection]
        //   var totalPages = curSection.length-3;
        //   var completedPages = 0;
        //   for(var i=0;i<totalPages;i++) {
        //       var curPage = curSection[i];
        //       if(curPage.status == 1) {
        //           completedPages++;
        //       }
        //   }
        // }
          var curSection = this.chapterProgressData[this.currentSection].pages
          var totalPages = this.data.sectionPagesForProgress.length;
          var completedPages = 0;
          for(var i=0;i<totalPages;i++) {
              var id = this.data.sectionPagesForProgress[i];
              var curPage = curSection[id];
              if(curPage.status == 1) {
                  completedPages++;
              }
        }
        result = (completedPages/totalPages) * 100;
        if(result >= 100) {
            result = 100;
        }
        return result
    }
  },
  mounted() {
    this.startLoad();
  },
  unmounted() {
      this.destroyMagnifier();
      this.emitter.off("hideInstruction");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.loading {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  
  border: 10px solid rgba(85, 182, 248, 1);
  border-top-color: rgba(85, 182, 248, .25);
  animation: spin 2s infinite linear;
  z-index:9999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:#FFF;
    z-index:9999;
}

.progressOutline {
    position:absolute;
    top:50%;
    left:50%;
    transform:translateX(-50%) translateY(-50%);
    width:75%;
    height:5%;
    max-height:15px;
    border-radius:25px;
    background:#333;
}
.progressFill {
    position:absolute;
    top:50%;
    left:50%;
    transform:translateX(-50%) translateY(-50%);
    width:75%;
    height:5%;
    max-height:15px;
    border-radius:25px;
    background:#55B6F8;
}
.hideMagGas {
    opacity:0.01;
}
.nav{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index:1;
    background:#FFF;
}
.navContainer {
    /*let's say your ratio is x:y
     let x be 1293
    let y be 1920*/
    width: 100vw;
    /* height= 100/(x/y) */
    height:148.4912vw; 
    max-height: 100vh;
    /*Max-width =  100*(x/y) */
    max-width:67.344vh;
    
    /*centralise horizontally and vertically*/
    position: absolute;
    top: 50%;
     left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: visible;
}
.header {
    position:fixed;
    top:0;
    left:0;
    height:15%;
    max-height:75px;
    width:100%;
    padding-top:10px;
    padding-bottom:10px;
    background:url("../assets/images/stroke white.png") no-repeat center center;
    background-size: 150% 120%;
    filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
    -webkit-filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
    -moz-filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
    z-index:999;
}

.magnify {
    z-index: 20;
	position: relative;
	width:100%;
    height:100%;
	margin:0px auto;
	overflow: visible;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}
.magnify img {
    display:block;
}
.element_to_magnify {
    position:absolute;
    height:100%;
    width:100%;   
    left:0;
    overflow: visible;
    /* background: url("../assets/images/sloot-test.png") no-repeat center bottom; */
    /* background-size:auto 100%; */
}

.navMap {
    left:50%;
    width:1000%;
    height:100%;
    transform: translateX(-50%);
    position:absolute;
}

.topBackgroundRepeat {
    background:url("../assets/images/map top repeat.png") center center no-repeat;
    background-size:auto 100%;
    background-repeat: no-repeat;
    position:absolute;
    bottom:50%;
    left:0;
    width:100%;
    height:50%;
}
.bottomBackgroundRepeat {
    background:url("../assets/images/map bottom repeat.png") center center no-repeat;
    background-size:auto 100%;
    position:absolute;
    top:50%;
    left:0;
    width:100%;
    height:50%;
}


.element_to_magnify button {
    position:absolute;
    top:30%;
    left:30%;
}

.magnify_glass {
    z-index: 300;
	position: absolute;
	width: 100px;
	height: 100px;
	cursor: move;
    top:110%;
    left:50%;
}
.magGlasImage {
    position:absolute;
    top:-6%;
    left:-8%;
    width:200%;
    z-index:19;
}
.mg_ring {
	background:none;
	position: absolute;
    -moz-box-shadow:    inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    -ms-box-shadow: inset 0 0 10px #000000;
    box-shadow:         inset 0 0 10px #000000;
	border: 4px solid #CCC;
	border-radius: 50%;
	width: 100%;
	height: 100%;
	z-index: 20;
}
.ringCollisionRed {
    animation-duration: .6s;
    animation-delay:1s;
    animation-name: blinkRed;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
}
.ringCollisionGreen {
    animation-duration: .6s;
    animation-delay:1s;
    animation-name: blinkGreen;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
}
@keyframes blinkGreen {
  from {
    border: 4px solid #CCC;
  }

  to {
    border: 4px solid #ffd000;
  }
}

@keyframes blinkRed {
  from {
    border: 4px solid #CCC;
  }

  to {
    border: 4px solid #ff3c00;
  }
}

.mg_zone {
	position: absolute;
	border-radius: 50%;
	top:0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 10;
	overflow:hidden;
}

.pm_btn {
	border: 4px solid #CCC;	
	z-index: 30;
	color: #333;
	position: absolute;
	width: 40px;
	height: 40px;
	background-color: #fff;
	border-radius: 50%;
	
}
.pm_btn h2{
	text-align: center;
	user-select: none;
	cursor: pointer;
}
.minus {
	right:-24px;
	bottom:30px;
}
.plus {
	right:0px;
	bottom:-5px;
}

.button {
	width:30px;
	height: 30px;
	padding-top: 5px;
	overflow: hidden;
	border-radius: 50%;
	background-color: rgba(50, 50, 50, .5);
	position: absolute;
	text-indent: -999em;
}
.test {
    position:absolute;
    top:60%;
    left:10%;
    width:50px;
    height:50px;
    opacity: 1;
    /* background: url("../assets/images/libelle-still.gif") no-repeat left top; */
    background-size:100% auto;
    opacity:1;
}
.test img {
    width:100%;
}
.isTouching {
    opacity: 1;
    /* background: url("../assets/images/libbelle-animated.gif") no-repeat left top; */
    background-size:100% auto;
}
.footer {
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height:15%;
    z-index:400;
    background: url("../assets/images/stroke white loop.png") repeat-x center top;
    background-size:auto 135%;
}
.buttonBig {
    font-family: "Soho";
    position:absolute;
    top:50%;
    left:50%;
    transform: translateY(-50%) translateX(-50%);

    font-size: calc(24px + 10 * ((100vw - 320px) / 680));
    /* color:#55B6F8; */
    color:#55B6F8;
    font-weight:900;
}

.buttonNav {
    display:inline-block;
    width:auto;
}

.buttonNext, .buttonPrev {
    font-family: "Soho";
    position:absolute;
    top:50%;
    transform: translateY(-50%);

 

    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    /* color:#55B6F8; */
    color:#757575;
    font-weight:900;
}


.buttonNext {
    right:1%;
}

.buttonPrev {
    left:1%;
}


.buttonBig::before {
    font-family: "FontAwesome";
    font-size:.9em;
    padding-right:0.5em;
    padding-left:0.2em;
    content: "    \f023";
}
.buttonBig::before {
    font-family: "FontAwesome";
    font-size:.9em;
    padding-right:0.5em;
    padding-left:0.2em;
    content: "";
}


.buttonLocked {
    /* color:#757575; */
    display:none;
}
.buttonUnlocked {
    color:#55B6F8;
}
</style>

