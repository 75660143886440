<template>
  <div class="appheader">
    <!-- <button class='button-back' @click="navBack" >terug</button> -->
    <!-- <h1>{{title}}</h1> -->
    <h1>3.WATERDIEREN</h1>
    <div class="progress">
      <div class="progressOutline"></div>
      <div class="progressFill"></div>
    </div>
    <button class="close"></button>
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
  props: {
    title: {
      type:String,
      default:"HOE SCHOON IS HET WATER?"
    }
  },
  methods: {
    navBack() {
      this.emitter.emit("nav-back");
    },
    goToMainMenu() {
      this.emitter.emit("goToMenu");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  position:absolute;
  top:5%;
  left:5%;
  font-size:1.5em;
  color:#55B6F8;
  font-family:"Waterlily";
  margin:0;
}
.appheader {
  position:fixed;
  top:0;
  left:0;
  height:15%;
  max-height:75px;
  width:100%;
  z-index:999;
  padding-top:10px;
  padding-bottom:10px;
  background:url("../assets/images/stroke white.png") no-repeat center center;
  background-size: 150% 120%;
  filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
  -webkit-filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
  -moz-filter: drop-shadow(1px 7px 12px rgba(0,0,0,0.24));
}
.button-back {
  position: absolute;
  top: 30%;
  left:2%;
  transform: translateY(-50%);
  font-size:1em;
  color:#3d3d3d;
  padding:0;
  font-weight:900;
}
.button-back::before {
  font-family: "FontAwesome";
  content: "\f053";
  padding-right:10px;
}

.button-menu {
  position: absolute;
  top: 30%;
  right:2%;
  transform: translateY(-50%);
  font-size:1em;
  color:#000;
  padding:0;
  font-weight:900;
}
.button-menu::after {
  font-family: "FontAwesome";
  content: "\f0c9";
  padding-left:10px;
}
.progress {
  position:absolute;
  left:5%;
  bottom:30%;
  width:75%;
  height:10%;
  border-radius:15px;
  background:#55B6F8;
}
.progressOutline {
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  border-radius:15px;
  background:#adadad;
}
.progressFill {
  position:absolute;
  left:0;
  top:0;
  width:10%;
  height:100%;
  border-radius:15px;
  background:#55B6F8;
}
.close {
  display:block;
  float:right;
  font-size:2em;
  padding:0;
  margin-right:5%;
  font-weight:900;
  color:#818181;
}
.close::after {
  font-family: "FontAwesome";
  content: "\f00d ";   
}
</style>
