<template>
  <div class="dragSingleTarget" @mousemove="mousemove" @mouseup="mouseUp" @touchend="touchEnd" @touchmove="touchMove" >
   <div class="title">
     <h1>Wat voor type water is dit?</h1>
     <div class="wavesContainer">
       <svg class="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none">
        <defs>
        <path id="gentle-wave"
        d="M-160 44c30 0 
            58-18 88-18s
            58 18 88 18 
            58-18 88-18 
            58 18 88 18
            v44h-352z" />
          </defs>
          <g class="parallax">
          <use xlink:href="#gentle-wave" x="50" y="0" fill="#80C085"/>
          <!-- <use xlink:href="#gentle-wave" x="50" y="3" fill="#E8E9F5"/>
          <use xlink:href="#gentle-wave" x="50" y="6" fill="#c2cad9"/>   -->
          </g>
        </svg>
     </div>
    </div>
   <div class="image"></div>
    <div class="bottomWaves">
        <svg class="svgWavesBottom"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none">
        <defs>
        <path id="gentle-wave"
        d="M-160 44c30 0 
            58-18 88-18s
            58 18 88 18 
            58-18 88-18 
            58 18 88 18
            v44h-352z" />
          </defs>
          <g class="parallaxBottom">
          <use xlink:href="#gentle-wave" x="50" y="0" fill="#0DA5B7"/>
          <use xlink:href="#gentle-wave" x="50" y="3" fill="#55B698"/>
          <use xlink:href="#gentle-wave" x="50" y="6" fill="#80C085"/>  
          </g>
        </svg>
      </div>
    <div id="itemContainer" class="itemContainer">
      <div class="item vijver"  @mousedown="mouseDown" @touchstart="touchStart" :style="'top:15%;left:2%;'" ></div>

    </div>
  </div>

  <!-- SVG -->
 
</template>

<script>

export default {
  name: 'dragSingleTarget',
  data() {
      return {
        dragTarget: null,
        dragStartX: 0,
        dragStartY: 0,
        isDragging: false,
        pos1: 0,
        pos2: 0,
        pos3: 0,
        pos4: 0,
        doFade: false
      }
  },
  props: {
  },
  methods: {
    touchStart(e) {
      if(e != null) {
        this.dragTarget = e.target;
        this.dragStartX = this.dragTarget.style.left
        this.dragStartY = this.dragTarget.style.top
        this.isDragging = true;

        this.pos3 = e.touches[0].clientX;
        this.pos4 = e.touches[0].clientY
      }
    },
    mouseDown(e) {
      if(e != null) {
      this.dragTarget = e.target;
      
      this.dragStartX = this.dragTarget.style.left
      this.dragStartY = this.dragTarget.style.top
      this.isDragging = true;

      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      }
    },
    mousemove(e) {
      if(e != null) {
      if(this.isDragging) {
        this.pos1 = this.pos3 - e.clientX;
         this.pos2 = this.pos4 - e.clientY;
          this.pos3 = e.clientX;
         this.pos4 = e.clientY;
         this.dragTarget.style.top = (this.dragTarget.offsetTop - this.pos2) + "px";
          this.dragTarget.style.left = (this.dragTarget.offsetLeft - this.pos1) + "px";
      }
      }
    },
    touchMove(e) {
      if(e != null) {
      if(this.isDragging) {
        this.pos1 = this.pos3 - e.touches[0].clientX;
         this.pos2 = this.pos4 -e.touches[0].clientY;
          this.pos3 = e.touches[0].clientX;
         this.pos4 = e.touches[0].clientY;
         this.dragTarget.style.top = (this.dragTarget.offsetTop - this.pos2) + "px";
          this.dragTarget.style.left = (this.dragTarget.offsetLeft - this.pos1) + "px";
      }
      }
    },
    mouseUp(e) {
      if(e != null) {
        this.isDragging = false;
        if(this.dragTarget.offsetTop < 0) {
          this.doFade = true;
        } else {
          this.dragTarget.style.top = this.dragStartY;
          this.dragTarget.style.left = this.dragStartX;
        }
      
      }
    },
    touchEnd(e) {
      if(e != null) {
        this.isDragging = false;
        if(this.dragTarget.offsetTop < 0) {
          this.doFade = true;
        } else {
          this.dragTarget.style.top = this.dragStartY;
          this.dragTarget.style.left = this.dragStartX;
        }
      
      }
    },
    mounted() {
     this.emitter.emit("hideHeader");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:25%;
  background: rgb(13,165,183);
  background: linear-gradient(180deg, rgba(13,165,183,1) 0%, rgba(85,182,152,1) 50%, rgba(128,192,133,1) 100%); 
  z-index:2;
}
h1 {
  position:absolute;
  bottom:0;
  left:0;
  margin:0;
  width:100%;
  height:30%;
  font-size:3.5vh;
  font-family: "N&M Utrecht";
  font-weight:900;
  color:#FFF;
}
.image {
  position:absolute;
  top:25%;
  height:45%;
  left:0;
  width:100%;
  z-index:0;
  background: url("../assets/images/watertypes/sloot2.jpg") no-repeat center center;
  background-size:contain;

}
.dragSingleTarget {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:#c2cad9;
  z-index:999;
}
.dock {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:30%;
}
.itemContainer {
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  height:30%;
  background: rgb(13,165,183);
  background: linear-gradient(0deg, rgba(13,165,183,1) 0%, rgba(85,182,152,1) 50%, rgba(128,192,133,1) 100%); 
}
.item {
  position:absolute;
  top:10%;
  left:10%;
  width:160px;
  height:100px;
  border-radius:50%;
  background:orange;
  opacity:1;
}
.sloot {
  background:url("../assets/images/sloot-gif.gif") no-repeat;
  background-size:contain;
}
.vijver {
  background:url("../assets/images/vijver-gif.gif") no-repeat;
  background-size:contain;
}
.meer {
  background:url("../assets/images/meer-gif.gif") no-repeat;
  background-size:contain;
}
.kanaal {
  background:url("../assets/images/kanaal-gif.gif") no-repeat;
  background-size:contain;
}
.fadeOut {
  transition: all 0.5s;
  opacity:0;
}
.wavesContainer {
  position:absolute;
  bottom:-5%;
  left:0;
  height:10%;
  width:100%;
}
.waves {
  display: block;
  width: 100%;
  height: 2em;
  max-height: 100vh;
  margin: 0;
  margin-top:-1px;
  transform: rotate(180deg);
}
::selection {
  background-color: salmon;
  color: white;
}

.parallax {
  
}
.parallax > use {
  animation: move-forever 12s linear infinite;
  
}
.parallax > use:nth-child(1) {
  animation-delay: -4s;
  
}
.parallax > use:nth-child(2) {
  animation-delay: -4s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -8s;
  animation-duration: 6s;
}

@keyframes move-forever {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

.bottomWaves {
  position:absolute;
  bottom:29.5%;
  left:0;
  height:2em;
  width:100%;
}
.svgWavesBottom {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
}
.parallaxBottom > use {
  animation: move-forever-parralaxBottom 12s linear infinite;
}
.parallaxBottom > use:nth-child(1) {
  animation-delay: -4s;
}
.parallaxBottom > use:nth-child(2) {
  animation-delay: -4s;
  animation-duration: 10s;
}
.parallaxBottom > use:nth-child(3) {
  animation-delay: -8s;
  animation-duration: 6s;
}

@keyframes move-forever-parralaxBottom {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
</style>
